/* tslint:disable */
/* eslint-disable */
/**
 * Prescription API
 * API for managing patient prescriptions and refills. <br/><br/>        Depends on: <br/>       - <b>PatientService</b> <a href=\"https://api.dev.lumistry.com/patient-service/\">(https://api.dev.lumistry.com/patient-service/)</a><br/>- <b>PmsIntegrationService</b> <a href=\"https://api.dev.lumistry.com/pms-integration-service/\">(https://api.dev.lumistry.com/pms-integration-service/)</a><br/>- <b>OrderService</b> <a href=\"https://api.dev.lumistry.com/order-service/\">(https://api.dev.lumistry.com/order-service/)</a>      
 *
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface CreatePrescriptionDto
 */
export interface CreatePrescriptionDto {
    /**
     * Pharmacy Id referenced from pharmacy-service
     * @type {string}
     * @memberof CreatePrescriptionDto
     */
    'pharmacy_id': string;
    /**
     * Location Id referenced from pharmacy-service
     * @type {string}
     * @memberof CreatePrescriptionDto
     */
    'location_id': string;
    /**
     * LocationPatientRecordId referenced from patient-service
     * @type {string}
     * @memberof CreatePrescriptionDto
     */
    'patient_id': string;
    /**
     * 
     * @type {IntegrationType}
     * @memberof CreatePrescriptionDto
     */
    'integration_type': IntegrationType;
    /**
     * 
     * @type {IngressMethod}
     * @memberof CreatePrescriptionDto
     */
    'ingress_method'?: IngressMethod;
    /**
     * Rx Number of prescription from PMS system
     * @type {string}
     * @memberof CreatePrescriptionDto
     */
    'rx_number': string;
    /**
     * Drug Name
     * @type {string}
     * @memberof CreatePrescriptionDto
     */
    'drug_name'?: string;
    /**
     * Drug Brand
     * @type {string}
     * @memberof CreatePrescriptionDto
     */
    'drug_brand'?: string;
    /**
     * FDA National Drug Code
     * @type {string}
     * @memberof CreatePrescriptionDto
     */
    'ndc'?: string;
    /**
     * Drug Code Version
     * @type {string}
     * @memberof CreatePrescriptionDto
     */
    'drug_code_ver'?: string;
    /**
     * Drug Code
     * @type {string}
     * @memberof CreatePrescriptionDto
     */
    'drug_code'?: string;
    /**
     * Last Refill Date (YYYY-MM-DD)
     * @type {string}
     * @memberof CreatePrescriptionDto
     */
    'last_refill_date'?: string;
    /**
     * Refills Remaining
     * @type {number}
     * @memberof CreatePrescriptionDto
     */
    'remaining_refills'?: number;
    /**
     * Sig
     * @type {string}
     * @memberof CreatePrescriptionDto
     */
    'sig'?: string;
    /**
     * 
     * @type {WorkflowStatus}
     * @memberof CreatePrescriptionDto
     */
    'workflow_status'?: WorkflowStatus;
    /**
     * 
     * @type {RefillStatus}
     * @memberof CreatePrescriptionDto
     */
    'refill_status'?: RefillStatus;
    /**
     * Date Written
     * @type {string}
     * @memberof CreatePrescriptionDto
     */
    'written_date'?: string;
    /**
     * Date First Filled
     * @type {string}
     * @memberof CreatePrescriptionDto
     */
    'first_filled_date'?: string;
    /**
     * Days Supply
     * @type {number}
     * @memberof CreatePrescriptionDto
     */
    'days_supply'?: number;
    /**
     * Tracking Number
     * @type {string}
     * @memberof CreatePrescriptionDto
     */
    'tracking_number'?: string;
    /**
     * 
     * @type {RxOrigin}
     * @memberof CreatePrescriptionDto
     */
    'rx_origin'?: RxOrigin;
    /**
     * Flag to Determine if a generic was dispensed
     * @type {boolean}
     * @memberof CreatePrescriptionDto
     */
    'is_generic_dispensed'?: boolean;
    /**
     * ICD Version
     * @type {string}
     * @memberof CreatePrescriptionDto
     */
    'icd_version'?: string;
    /**
     * ICD Code
     * @type {string}
     * @memberof CreatePrescriptionDto
     */
    'icd_code'?: string;
    /**
     * Flag to determine if the medication is PRN
     * @type {boolean}
     * @memberof CreatePrescriptionDto
     */
    'is_prn'?: boolean;
    /**
     * Expiration Date (YYYYMMDD)
     * @type {string}
     * @memberof CreatePrescriptionDto
     */
    'expiration_date'?: string;
    /**
     * Reassigned Rx Number
     * @type {string}
     * @memberof CreatePrescriptionDto
     */
    'reassigned_rx_number'?: string;
    /**
     * Quantity On Hand
     * @type {number}
     * @memberof CreatePrescriptionDto
     */
    'quantity_on_hand'?: number;
    /**
     * Co-Pay (amount)
     * @type {string}
     * @memberof CreatePrescriptionDto
     */
    'co_pay'?: string;
    /**
     * Last Fulfillment Date (YYYYMMDD) medication was handed off to patient, whether pickup mail, delivery, etc
     * @type {string}
     * @memberof CreatePrescriptionDto
     */
    'last_fulfillment_date'?: string;
    /**
     * 
     * @type {FulfillmentMethod}
     * @memberof CreatePrescriptionDto
     */
    'last_fulfillment_method'?: FulfillmentMethod;
    /**
     * Last Refill Quantity
     * @type {number}
     * @memberof CreatePrescriptionDto
     */
    'last_refill_quantity'?: number;
    /**
     * Original Refills Authorized
     * @type {number}
     * @memberof CreatePrescriptionDto
     */
    'original_refills_authorized'?: number;
    /**
     * The number indicating which refill (ex. 1 of 3 total) for this prescription
     * @type {number}
     * @memberof CreatePrescriptionDto
     */
    'refill_number'?: number;
    /**
     * Next Refill Date (YYYYMMDD)
     * @type {string}
     * @memberof CreatePrescriptionDto
     */
    'next_refill_date'?: string;
    /**
     * Quantity Remaining
     * @type {number}
     * @memberof CreatePrescriptionDto
     */
    'quantity_remaining'?: number;
    /**
     * Quantity Written
     * @type {number}
     * @memberof CreatePrescriptionDto
     */
    'quantity_written'?: number;
    /**
     * Original Fill Quantity
     * @type {number}
     * @memberof CreatePrescriptionDto
     */
    'original_fill_quantity'?: number;
    /**
     * Flag to determine if the prescription container has an easy to open cap
     * @type {boolean}
     * @memberof CreatePrescriptionDto
     */
    'is_easy_open_cap'?: boolean;
    /**
     * Drug Scheduling as identified by the FDA
     * @type {string}
     * @memberof CreatePrescriptionDto
     */
    'schedule'?: string;
    /**
     * Flag to indicate if the medication is intended for a pet
     * @type {boolean}
     * @memberof CreatePrescriptionDto
     */
    'is_pet'?: boolean;
    /**
     * Flag to indicate if the medication is compounded
     * @type {boolean}
     * @memberof CreatePrescriptionDto
     */
    'is_compounded'?: boolean;
    /**
     * Flag to indicate if the medication is over the counter
     * @type {boolean}
     * @memberof CreatePrescriptionDto
     */
    'is_over_the_counter'?: boolean;
    /**
     * Flag to indicate if the medication may be enrolled into an auto refill program
     * @type {boolean}
     * @memberof CreatePrescriptionDto
     */
    'is_auto_fill_allowed'?: boolean;
    /**
     * Flag to indicate if the medication is enrolled in an auto refill program. Note, this is only eligible to be true if is_auto_fill_allowed=true.
     * @type {boolean}
     * @memberof CreatePrescriptionDto
     */
    'auto_fill_enrolled'?: boolean;
    /**
     * Doctor First Name
     * @type {string}
     * @memberof CreatePrescriptionDto
     */
    'doctor_first_name'?: string;
    /**
     * Doctor Last Name
     * @type {string}
     * @memberof CreatePrescriptionDto
     */
    'doctor_last_name'?: string;
    /**
     * Doctor NPI Code
     * @type {string}
     * @memberof CreatePrescriptionDto
     */
    'doctor_npi'?: string;
    /**
     * Doctor Phone Number
     * @type {string}
     * @memberof CreatePrescriptionDto
     */
    'doctor_phone'?: string;
    /**
     * Doctor Fax Number
     * @type {string}
     * @memberof CreatePrescriptionDto
     */
    'doctor_fax'?: string;
    /**
     * Doctor Address 1
     * @type {string}
     * @memberof CreatePrescriptionDto
     */
    'doctor_address_1'?: string;
    /**
     * Doctor Address 2
     * @type {string}
     * @memberof CreatePrescriptionDto
     */
    'doctor_address_2'?: string;
    /**
     * Doctor Address City
     * @type {string}
     * @memberof CreatePrescriptionDto
     */
    'doctor_address_city'?: string;
    /**
     * Doctor Address State
     * @type {string}
     * @memberof CreatePrescriptionDto
     */
    'doctor_address_state'?: string;
    /**
     * Doctor Address Zip Code
     * @type {string}
     * @memberof CreatePrescriptionDto
     */
    'doctor_address_zip'?: string;
    /**
     * Doctor Address Country
     * @type {string}
     * @memberof CreatePrescriptionDto
     */
    'doctor_address_country'?: string;
    /**
     * Doctor DEA Code
     * @type {string}
     * @memberof CreatePrescriptionDto
     */
    'doctor_dea'?: string;
    /**
     * Doctor DEA State
     * @type {string}
     * @memberof CreatePrescriptionDto
     */
    'doctor_dea_state'?: string;
    /**
     * 
     * @type {DoctorContactMethod}
     * @memberof CreatePrescriptionDto
     */
    'doctor_preferred_contact_method'?: DoctorContactMethod;
    /**
     * Flag to determine if doctor authorize refill
     * @type {boolean}
     * @memberof CreatePrescriptionDto
     */
    'doctor_refill_authorization_allowed'?: boolean;
    /**
     * Flag to determine if doctor allows to be contacted from patient
     * @type {boolean}
     * @memberof CreatePrescriptionDto
     */
    'doctor_contact_prescriber_allowed'?: boolean;
    /**
     * Last Order Date (YYYY-MM-DD)
     * @type {string}
     * @memberof CreatePrescriptionDto
     */
    'last_order_date'?: string;
    /**
     * Determines if the prescription is active or not.
     * @type {boolean}
     * @memberof CreatePrescriptionDto
     */
    'is_active'?: boolean;
}
/**
 * 
 * @export
 * @interface CreateRefillDto
 */
export interface CreateRefillDto {
    /**
     * Pharmacy Identifier
     * @type {string}
     * @memberof CreateRefillDto
     */
    'pharmacy_id': string;
    /**
     * Location Identifier
     * @type {string}
     * @memberof CreateRefillDto
     */
    'location_id': string;
    /**
     * Patient Record Location Id related to patient-service
     * @type {string}
     * @memberof CreateRefillDto
     */
    'patient_id': string;
    /**
     * Order Id if this related to any order on order-service
     * @type {string}
     * @memberof CreateRefillDto
     */
    'order_id'?: string;
    /**
     * 
     * @type {FulfillmentMethod}
     * @memberof CreateRefillDto
     */
    'fulfillment_method': FulfillmentMethod;
    /**
     * 
     * @type {IngressMethod}
     * @memberof CreateRefillDto
     */
    'ingress_method': IngressMethod;
    /**
     * True if the refill is urgent
     * @type {boolean}
     * @memberof CreateRefillDto
     */
    'is_urgent'?: boolean;
    /**
     * True if the refill is required for today
     * @type {boolean}
     * @memberof CreateRefillDto
     */
    'is_today'?: boolean;
    /**
     * Notes that may be displayed to pharmacist.
     * @type {string}
     * @memberof CreateRefillDto
     */
    'notes'?: string;
    /**
     * Patient User Identifier (user-service)
     * @type {string}
     * @memberof CreateRefillDto
     */
    'submitted_by': string;
    /**
     * List of Refill item prescription.
     * @type {Array<CreateRefillItemDto>}
     * @memberof CreateRefillDto
     */
    'refill_items': Array<CreateRefillItemDto>;
}
/**
 * 
 * @export
 * @interface CreateRefillItemDto
 */
export interface CreateRefillItemDto {
    /**
     * Rx number of the prescription
     * @type {string}
     * @memberof CreateRefillItemDto
     */
    'rx_number': string;
    /**
     * Rx name of the prescription
     * @type {string}
     * @memberof CreateRefillItemDto
     */
    'rx_name'?: string;
    /**
     * 
     * @type {RefillItemDtoPrescription}
     * @memberof CreateRefillItemDto
     */
    'prescription'?: RefillItemDtoPrescription;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DoctorContactMethod = {
    Email: 'email',
    Phone: 'phone',
    Fax: 'fax'
} as const;

export type DoctorContactMethod = typeof DoctorContactMethod[keyof typeof DoctorContactMethod];


/**
 * 
 * @export
 * @enum {string}
 */

export const FulfillmentMethod = {
    Pickup: 'pickup',
    Mail: 'mail',
    Delivery: 'delivery',
    Shipping: 'shipping',
    Courier: 'courier',
    DriveThru: 'drive_thru',
    Curbside: 'curbside',
    Counter: 'counter',
    Other: 'other'
} as const;

export type FulfillmentMethod = typeof FulfillmentMethod[keyof typeof FulfillmentMethod];


/**
 * 
 * @export
 * @interface HealthStatus
 */
export interface HealthStatus {
    /**
     * Status of the service. Can be UP or DOWN.
     * @type {string}
     * @memberof HealthStatus
     */
    'status': HealthStatusStatusEnum;
    /**
     * Timestamp of status.
     * @type {string}
     * @memberof HealthStatus
     */
    'timestamp': string;
    /**
     * Simple message in case of error
     * @type {string}
     * @memberof HealthStatus
     */
    'message'?: string;
}

export const HealthStatusStatusEnum = {
    Up: 'UP',
    Down: 'DOWN'
} as const;

export type HealthStatusStatusEnum = typeof HealthStatusStatusEnum[keyof typeof HealthStatusStatusEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const IngressMethod = {
    Web: 'web',
    Ivr: 'ivr',
    Mobile: 'mobile'
} as const;

export type IngressMethod = typeof IngressMethod[keyof typeof IngressMethod];


/**
 * 
 * @export
 * @enum {string}
 */

export const IntegrationType = {
    Manual: 'Manual',
    Integrated: 'Integrated',
    DataFeed: 'DataFeed'
} as const;

export type IntegrationType = typeof IntegrationType[keyof typeof IntegrationType];


/**
 * 
 * @export
 * @interface LocationConfigDto
 */
export interface LocationConfigDto {
    /**
     * Location UUID Identifier
     * @type {string}
     * @memberof LocationConfigDto
     */
    'location_id': string;
    /**
     * 
     * @type {IntegrationType}
     * @memberof LocationConfigDto
     */
    'integration_type'?: IntegrationType;
    /**
     * Type of Fulfilment methods that this location supports.
     * @type {Array<FulfillmentMethod>}
     * @memberof LocationConfigDto
     */
    'fulfilment_methods'?: Array<FulfillmentMethod>;
    /**
     * Allow special pickup method today that this locations supports
     * @type {boolean}
     * @memberof LocationConfigDto
     */
    'allowPickupToday': boolean;
    /**
     * Allow special pickup method urgent that this locations supports
     * @type {boolean}
     * @memberof LocationConfigDto
     */
    'allowPickupUrgent': boolean;
}
/**
 * 
 * @export
 * @interface MigratePrescriptionDto
 */
export interface MigratePrescriptionDto {
    /**
     * Pharmacy Id referenced from pharmacy-service
     * @type {string}
     * @memberof MigratePrescriptionDto
     */
    'pharmacy_id': string;
    /**
     * Location Id referenced from pharmacy-service
     * @type {string}
     * @memberof MigratePrescriptionDto
     */
    'location_id': string;
    /**
     * LocationPatientRecordId referenced from patient-service
     * @type {string}
     * @memberof MigratePrescriptionDto
     */
    'patient_id': string;
    /**
     * 
     * @type {string}
     * @memberof MigratePrescriptionDto
     */
    'patient_gender'?: string;
    /**
     * 
     * @type {IntegrationType}
     * @memberof MigratePrescriptionDto
     */
    'integration_type': IntegrationType;
    /**
     * 
     * @type {IngressMethod}
     * @memberof MigratePrescriptionDto
     */
    'ingress_method'?: IngressMethod;
    /**
     * Rx Number of prescription from PMS system
     * @type {string}
     * @memberof MigratePrescriptionDto
     */
    'rx_number': string;
    /**
     * Drug Name
     * @type {string}
     * @memberof MigratePrescriptionDto
     */
    'drug_name'?: string;
    /**
     * Drug Brand
     * @type {string}
     * @memberof MigratePrescriptionDto
     */
    'drug_brand'?: string;
    /**
     * FDA National Drug Code
     * @type {string}
     * @memberof MigratePrescriptionDto
     */
    'ndc'?: string;
    /**
     * Drug Code Version
     * @type {string}
     * @memberof MigratePrescriptionDto
     */
    'drug_code_ver'?: string;
    /**
     * Drug Code
     * @type {string}
     * @memberof MigratePrescriptionDto
     */
    'drug_code'?: string;
    /**
     * Last Refill Date (YYYY-MM-DD)
     * @type {string}
     * @memberof MigratePrescriptionDto
     */
    'last_refill_date'?: string;
    /**
     * Refills Remaining
     * @type {number}
     * @memberof MigratePrescriptionDto
     */
    'remaining_refills'?: number;
    /**
     * Sig
     * @type {string}
     * @memberof MigratePrescriptionDto
     */
    'sig'?: string;
    /**
     * 
     * @type {WorkflowStatus}
     * @memberof MigratePrescriptionDto
     */
    'workflow_status'?: WorkflowStatus;
    /**
     * 
     * @type {RefillStatus}
     * @memberof MigratePrescriptionDto
     */
    'refill_status'?: RefillStatus;
    /**
     * Date Written
     * @type {string}
     * @memberof MigratePrescriptionDto
     */
    'written_date'?: string;
    /**
     * Date First Filled
     * @type {string}
     * @memberof MigratePrescriptionDto
     */
    'first_filled_date'?: string;
    /**
     * Days Supply
     * @type {number}
     * @memberof MigratePrescriptionDto
     */
    'days_supply'?: number;
    /**
     * Tracking Number
     * @type {string}
     * @memberof MigratePrescriptionDto
     */
    'tracking_number'?: string;
    /**
     * 
     * @type {RxOrigin}
     * @memberof MigratePrescriptionDto
     */
    'rx_origin'?: RxOrigin;
    /**
     * Flag to Determine if a generic was dispensed
     * @type {boolean}
     * @memberof MigratePrescriptionDto
     */
    'is_generic_dispensed'?: boolean;
    /**
     * ICD Version
     * @type {string}
     * @memberof MigratePrescriptionDto
     */
    'icd_version'?: string;
    /**
     * ICD Code
     * @type {string}
     * @memberof MigratePrescriptionDto
     */
    'icd_code'?: string;
    /**
     * Flag to determine if the medication is PRN
     * @type {boolean}
     * @memberof MigratePrescriptionDto
     */
    'is_prn'?: boolean;
    /**
     * Expiration Date (YYYYMMDD)
     * @type {string}
     * @memberof MigratePrescriptionDto
     */
    'expiration_date'?: string;
    /**
     * Reassigned Rx Number
     * @type {string}
     * @memberof MigratePrescriptionDto
     */
    'reassigned_rx_number'?: string;
    /**
     * Quantity On Hand
     * @type {number}
     * @memberof MigratePrescriptionDto
     */
    'quantity_on_hand'?: number;
    /**
     * Co-Pay (amount)
     * @type {string}
     * @memberof MigratePrescriptionDto
     */
    'co_pay'?: string;
    /**
     * Last Fulfillment Date (YYYYMMDD) medication was handed off to patient, whether pickup mail, delivery, etc
     * @type {string}
     * @memberof MigratePrescriptionDto
     */
    'last_fulfillment_date'?: string;
    /**
     * 
     * @type {FulfillmentMethod}
     * @memberof MigratePrescriptionDto
     */
    'last_fulfillment_method'?: FulfillmentMethod;
    /**
     * Last Refill Quantity
     * @type {number}
     * @memberof MigratePrescriptionDto
     */
    'last_refill_quantity'?: number;
    /**
     * Original Refills Authorized
     * @type {number}
     * @memberof MigratePrescriptionDto
     */
    'original_refills_authorized'?: number;
    /**
     * The number indicating which refill (ex. 1 of 3 total) for this prescription
     * @type {number}
     * @memberof MigratePrescriptionDto
     */
    'refill_number'?: number;
    /**
     * Next Refill Date (YYYYMMDD)
     * @type {string}
     * @memberof MigratePrescriptionDto
     */
    'next_refill_date'?: string;
    /**
     * Quantity Remaining
     * @type {number}
     * @memberof MigratePrescriptionDto
     */
    'quantity_remaining'?: number;
    /**
     * Quantity Written
     * @type {number}
     * @memberof MigratePrescriptionDto
     */
    'quantity_written'?: number;
    /**
     * Original Fill Quantity
     * @type {number}
     * @memberof MigratePrescriptionDto
     */
    'original_fill_quantity'?: number;
    /**
     * Flag to determine if the prescription container has an easy to open cap
     * @type {boolean}
     * @memberof MigratePrescriptionDto
     */
    'is_easy_open_cap'?: boolean;
    /**
     * Drug Scheduling as identified by the FDA
     * @type {string}
     * @memberof MigratePrescriptionDto
     */
    'schedule'?: string;
    /**
     * Flag to indicate if the medication is intended for a pet
     * @type {boolean}
     * @memberof MigratePrescriptionDto
     */
    'is_pet'?: boolean;
    /**
     * Flag to indicate if the medication is compounded
     * @type {boolean}
     * @memberof MigratePrescriptionDto
     */
    'is_compounded'?: boolean;
    /**
     * Flag to indicate if the medication is over the counter
     * @type {boolean}
     * @memberof MigratePrescriptionDto
     */
    'is_over_the_counter'?: boolean;
    /**
     * Flag to indicate if the medication may be enrolled into an auto refill program
     * @type {boolean}
     * @memberof MigratePrescriptionDto
     */
    'is_auto_fill_allowed'?: boolean;
    /**
     * Flag to indicate if the medication is enrolled in an auto refill program. Note, this is only eligible to be true if is_auto_fill_allowed=true.
     * @type {boolean}
     * @memberof MigratePrescriptionDto
     */
    'auto_fill_enrolled'?: boolean;
    /**
     * Determines if the prescription is active or not.
     * @type {boolean}
     * @memberof MigratePrescriptionDto
     */
    'is_active': boolean;
    /**
     * Doctor First Name
     * @type {string}
     * @memberof MigratePrescriptionDto
     */
    'doctor_first_name'?: string;
    /**
     * Doctor Last Name
     * @type {string}
     * @memberof MigratePrescriptionDto
     */
    'doctor_last_name'?: string;
    /**
     * Doctor NPI Code
     * @type {string}
     * @memberof MigratePrescriptionDto
     */
    'doctor_npi'?: string;
    /**
     * Doctor Phone Number
     * @type {string}
     * @memberof MigratePrescriptionDto
     */
    'doctor_phone'?: string;
    /**
     * Doctor Fax Number
     * @type {string}
     * @memberof MigratePrescriptionDto
     */
    'doctor_fax'?: string;
    /**
     * Doctor Address 1
     * @type {string}
     * @memberof MigratePrescriptionDto
     */
    'doctor_address_1'?: string;
    /**
     * Doctor Address 2
     * @type {string}
     * @memberof MigratePrescriptionDto
     */
    'doctor_address_2'?: string;
    /**
     * Doctor Address City
     * @type {string}
     * @memberof MigratePrescriptionDto
     */
    'doctor_address_city'?: string;
    /**
     * Doctor Address State
     * @type {string}
     * @memberof MigratePrescriptionDto
     */
    'doctor_address_state'?: string;
    /**
     * Doctor Address Zip Code
     * @type {string}
     * @memberof MigratePrescriptionDto
     */
    'doctor_address_zip'?: string;
    /**
     * Doctor Address Country
     * @type {string}
     * @memberof MigratePrescriptionDto
     */
    'doctor_address_country'?: string;
    /**
     * Doctor DEA Code
     * @type {string}
     * @memberof MigratePrescriptionDto
     */
    'doctor_dea'?: string;
    /**
     * Doctor DEA State
     * @type {string}
     * @memberof MigratePrescriptionDto
     */
    'doctor_dea_state'?: string;
    /**
     * 
     * @type {DoctorContactMethod}
     * @memberof MigratePrescriptionDto
     */
    'doctor_preferred_contact_method'?: DoctorContactMethod;
    /**
     * Flag to determine if doctor authorize refill
     * @type {boolean}
     * @memberof MigratePrescriptionDto
     */
    'doctor_refill_authorization_allowed'?: boolean;
    /**
     * Flag to determine if doctor allows to be contacted from patient
     * @type {boolean}
     * @memberof MigratePrescriptionDto
     */
    'doctor_contact_prescriber_allowed'?: boolean;
    /**
     * Last Order Date (YYYY-MM-DD)
     * @type {string}
     * @memberof MigratePrescriptionDto
     */
    'last_order_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof MigratePrescriptionDto
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof MigratePrescriptionDto
     */
    'updated_at': string;
    /**
     * 
     * @type {string}
     * @memberof MigratePrescriptionDto
     */
    'patient_first_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof MigratePrescriptionDto
     */
    'patient_last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof MigratePrescriptionDto
     */
    'patient_date_of_birth'?: string;
}
/**
 * 
 * @export
 * @interface MigrateRefillDto
 */
export interface MigrateRefillDto {
    /**
     * Pharmacy Identifier
     * @type {string}
     * @memberof MigrateRefillDto
     */
    'pharmacy_id': string;
    /**
     * Location Identifier
     * @type {string}
     * @memberof MigrateRefillDto
     */
    'location_id': string;
    /**
     * Patient Record Location Id related to patient-service
     * @type {string}
     * @memberof MigrateRefillDto
     */
    'patient_id': string;
    /**
     * Order Id if this related to any order on order-service
     * @type {string}
     * @memberof MigrateRefillDto
     */
    'order_id'?: string;
    /**
     * 
     * @type {FulfillmentMethod}
     * @memberof MigrateRefillDto
     */
    'fulfillment_method': FulfillmentMethod;
    /**
     * 
     * @type {IngressMethod}
     * @memberof MigrateRefillDto
     */
    'ingress_method': IngressMethod;
    /**
     * True if the refill is urgent
     * @type {boolean}
     * @memberof MigrateRefillDto
     */
    'is_urgent'?: boolean;
    /**
     * True if the refill is required for today
     * @type {boolean}
     * @memberof MigrateRefillDto
     */
    'is_today'?: boolean;
    /**
     * Notes that may be displayed to pharmacist.
     * @type {string}
     * @memberof MigrateRefillDto
     */
    'notes'?: string;
    /**
     * Patient User Identifier (user-service)
     * @type {string}
     * @memberof MigrateRefillDto
     */
    'submitted_by': string;
    /**
     * List of Refill item prescription.
     * @type {Array<MigrateRefillItemDto>}
     * @memberof MigrateRefillDto
     */
    'refill_items': Array<MigrateRefillItemDto>;
    /**
     * Generate date when the refill is submitted.
     * @type {string}
     * @memberof MigrateRefillDto
     */
    'submitted_at': string;
    /**
     * Generate date when the refill is created.
     * @type {string}
     * @memberof MigrateRefillDto
     */
    'created_at': string;
    /**
     * Generate date when the refill is updated.
     * @type {string}
     * @memberof MigrateRefillDto
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface MigrateRefillItemDto
 */
export interface MigrateRefillItemDto {
    /**
     * Rx number of the prescription
     * @type {string}
     * @memberof MigrateRefillItemDto
     */
    'rx_number': string;
    /**
     * Rx name of the prescription
     * @type {string}
     * @memberof MigrateRefillItemDto
     */
    'rx_name'?: string;
    /**
     * 
     * @type {RefillItemDtoPrescription}
     * @memberof MigrateRefillItemDto
     */
    'prescription'?: RefillItemDtoPrescription;
    /**
     * Generate date when the refill is updated.
     * @type {string}
     * @memberof MigrateRefillItemDto
     */
    'updated_at': string;
    /**
     * Generate date when the refill is created.
     * @type {string}
     * @memberof MigrateRefillItemDto
     */
    'created_at': string;
}
/**
 * 
 * @export
 * @interface PrescriptionDto
 */
export interface PrescriptionDto {
    /**
     * Prescription Id generated from prescription-service
     * @type {string}
     * @memberof PrescriptionDto
     */
    'prescription_id': string;
    /**
     * Pharmacy Id referenced from pharmacy-service
     * @type {string}
     * @memberof PrescriptionDto
     */
    'pharmacy_id': string;
    /**
     * Location Id referenced from pharmacy-service
     * @type {string}
     * @memberof PrescriptionDto
     */
    'location_id': string;
    /**
     * LocationPatientRecordId referenced from patient-service
     * @type {string}
     * @memberof PrescriptionDto
     */
    'patient_id': string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionDto
     */
    'patient_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionDto
     */
    'patient_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionDto
     */
    'patient_date_of_birth': string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionDto
     */
    'patient_gender'?: string;
    /**
     * 
     * @type {IntegrationType}
     * @memberof PrescriptionDto
     */
    'integration_type': IntegrationType;
    /**
     * 
     * @type {IngressMethod}
     * @memberof PrescriptionDto
     */
    'ingress_method'?: IngressMethod;
    /**
     * Rx Number of prescription from PMS system
     * @type {string}
     * @memberof PrescriptionDto
     */
    'rx_number': string;
    /**
     * Drug Name
     * @type {string}
     * @memberof PrescriptionDto
     */
    'drug_name'?: string;
    /**
     * Drug Brand
     * @type {string}
     * @memberof PrescriptionDto
     */
    'drug_brand'?: string;
    /**
     * FDA National Drug Code
     * @type {string}
     * @memberof PrescriptionDto
     */
    'ndc'?: string;
    /**
     * Drug Code Version
     * @type {string}
     * @memberof PrescriptionDto
     */
    'drug_code_ver'?: string;
    /**
     * Drug Code
     * @type {string}
     * @memberof PrescriptionDto
     */
    'drug_code'?: string;
    /**
     * Last Refill Date (YYYY-MM-DD)
     * @type {string}
     * @memberof PrescriptionDto
     */
    'last_refill_date'?: string;
    /**
     * Refills Remaining
     * @type {number}
     * @memberof PrescriptionDto
     */
    'remaining_refills'?: number;
    /**
     * Sig
     * @type {string}
     * @memberof PrescriptionDto
     */
    'sig'?: string;
    /**
     * 
     * @type {WorkflowStatus}
     * @memberof PrescriptionDto
     */
    'workflow_status'?: WorkflowStatus;
    /**
     * 
     * @type {RefillStatus}
     * @memberof PrescriptionDto
     */
    'refill_status'?: RefillStatus;
    /**
     * Date Written
     * @type {string}
     * @memberof PrescriptionDto
     */
    'written_date'?: string;
    /**
     * Date First Filled
     * @type {string}
     * @memberof PrescriptionDto
     */
    'first_filled_date'?: string;
    /**
     * Days Supply
     * @type {number}
     * @memberof PrescriptionDto
     */
    'days_supply'?: number;
    /**
     * Tracking Number
     * @type {string}
     * @memberof PrescriptionDto
     */
    'tracking_number'?: string;
    /**
     * 
     * @type {RxOrigin}
     * @memberof PrescriptionDto
     */
    'rx_origin'?: RxOrigin;
    /**
     * Flag to Determine if a generic was dispensed
     * @type {boolean}
     * @memberof PrescriptionDto
     */
    'is_generic_dispensed'?: boolean;
    /**
     * ICD Version
     * @type {string}
     * @memberof PrescriptionDto
     */
    'icd_version'?: string;
    /**
     * ICD Code
     * @type {string}
     * @memberof PrescriptionDto
     */
    'icd_code'?: string;
    /**
     * Flag to determine if the medication is PRN
     * @type {boolean}
     * @memberof PrescriptionDto
     */
    'is_prn'?: boolean;
    /**
     * Expiration Date (YYYYMMDD)
     * @type {string}
     * @memberof PrescriptionDto
     */
    'expiration_date'?: string;
    /**
     * Reassigned Rx Number
     * @type {string}
     * @memberof PrescriptionDto
     */
    'reassigned_rx_number'?: string;
    /**
     * Quantity On Hand
     * @type {number}
     * @memberof PrescriptionDto
     */
    'quantity_on_hand'?: number;
    /**
     * Co-Pay (amount)
     * @type {string}
     * @memberof PrescriptionDto
     */
    'co_pay'?: string;
    /**
     * Last Fulfillment Date (YYYYMMDD) medication was handed off to patient, whether pickup mail, delivery, etc
     * @type {string}
     * @memberof PrescriptionDto
     */
    'last_fulfillment_date'?: string;
    /**
     * 
     * @type {FulfillmentMethod}
     * @memberof PrescriptionDto
     */
    'last_fulfillment_method'?: FulfillmentMethod;
    /**
     * Last Refill Quantity
     * @type {number}
     * @memberof PrescriptionDto
     */
    'last_refill_quantity'?: number;
    /**
     * Original Refills Authorized
     * @type {number}
     * @memberof PrescriptionDto
     */
    'original_refills_authorized'?: number;
    /**
     * The number indicating which refill (ex. 1 of 3 total) for this prescription
     * @type {number}
     * @memberof PrescriptionDto
     */
    'refill_number'?: number;
    /**
     * Next Refill Date (YYYYMMDD)
     * @type {string}
     * @memberof PrescriptionDto
     */
    'next_refill_date'?: string;
    /**
     * Quantity Remaining
     * @type {number}
     * @memberof PrescriptionDto
     */
    'quantity_remaining'?: number;
    /**
     * Quantity Written
     * @type {number}
     * @memberof PrescriptionDto
     */
    'quantity_written'?: number;
    /**
     * Original Fill Quantity
     * @type {number}
     * @memberof PrescriptionDto
     */
    'original_fill_quantity'?: number;
    /**
     * Flag to determine if the prescription container has an easy to open cap
     * @type {boolean}
     * @memberof PrescriptionDto
     */
    'is_easy_open_cap'?: boolean;
    /**
     * Drug Scheduling as identified by the FDA
     * @type {string}
     * @memberof PrescriptionDto
     */
    'schedule'?: string;
    /**
     * Flag to indicate if the medication is intended for a pet
     * @type {boolean}
     * @memberof PrescriptionDto
     */
    'is_pet'?: boolean;
    /**
     * Flag to indicate if the medication is compounded
     * @type {boolean}
     * @memberof PrescriptionDto
     */
    'is_compounded'?: boolean;
    /**
     * Flag to indicate if the medication is over the counter
     * @type {boolean}
     * @memberof PrescriptionDto
     */
    'is_over_the_counter'?: boolean;
    /**
     * Flag to indicate if the medication may be enrolled into an auto refill program
     * @type {boolean}
     * @memberof PrescriptionDto
     */
    'is_auto_fill_allowed'?: boolean;
    /**
     * Flag to indicate if the medication is enrolled in an auto refill program. Note, this is only eligible to be true if is_auto_fill_allowed=true.
     * @type {boolean}
     * @memberof PrescriptionDto
     */
    'auto_fill_enrolled'?: boolean;
    /**
     * Determines if the prescription is active or not.
     * @type {boolean}
     * @memberof PrescriptionDto
     */
    'is_active': boolean;
    /**
     * Doctor First Name
     * @type {string}
     * @memberof PrescriptionDto
     */
    'doctor_first_name'?: string;
    /**
     * Doctor Last Name
     * @type {string}
     * @memberof PrescriptionDto
     */
    'doctor_last_name'?: string;
    /**
     * Doctor NPI Code
     * @type {string}
     * @memberof PrescriptionDto
     */
    'doctor_npi'?: string;
    /**
     * Doctor Phone Number
     * @type {string}
     * @memberof PrescriptionDto
     */
    'doctor_phone'?: string;
    /**
     * Doctor Fax Number
     * @type {string}
     * @memberof PrescriptionDto
     */
    'doctor_fax'?: string;
    /**
     * Doctor Address 1
     * @type {string}
     * @memberof PrescriptionDto
     */
    'doctor_address_1'?: string;
    /**
     * Doctor Address 2
     * @type {string}
     * @memberof PrescriptionDto
     */
    'doctor_address_2'?: string;
    /**
     * Doctor Address City
     * @type {string}
     * @memberof PrescriptionDto
     */
    'doctor_address_city'?: string;
    /**
     * Doctor Address State
     * @type {string}
     * @memberof PrescriptionDto
     */
    'doctor_address_state'?: string;
    /**
     * Doctor Address Zip Code
     * @type {string}
     * @memberof PrescriptionDto
     */
    'doctor_address_zip'?: string;
    /**
     * Doctor Address Country
     * @type {string}
     * @memberof PrescriptionDto
     */
    'doctor_address_country'?: string;
    /**
     * Doctor DEA Code
     * @type {string}
     * @memberof PrescriptionDto
     */
    'doctor_dea'?: string;
    /**
     * Doctor DEA State
     * @type {string}
     * @memberof PrescriptionDto
     */
    'doctor_dea_state'?: string;
    /**
     * 
     * @type {DoctorContactMethod}
     * @memberof PrescriptionDto
     */
    'doctor_preferred_contact_method'?: DoctorContactMethod;
    /**
     * Flag to determine if doctor authorize refill
     * @type {boolean}
     * @memberof PrescriptionDto
     */
    'doctor_refill_authorization_allowed'?: boolean;
    /**
     * Flag to determine if doctor allows to be contacted from patient
     * @type {boolean}
     * @memberof PrescriptionDto
     */
    'doctor_contact_prescriber_allowed'?: boolean;
    /**
     * Last Order Date (YYYY-MM-DD)
     * @type {string}
     * @memberof PrescriptionDto
     */
    'last_order_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionDto
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof PrescriptionDto
     */
    'updated_at': string;
}
/**
 * 
 * @export
 * @interface RefillDto
 */
export interface RefillDto {
    /**
     * Refill Identifier
     * @type {string}
     * @memberof RefillDto
     */
    'refill_id': string;
    /**
     * Pharmacy Identifier
     * @type {string}
     * @memberof RefillDto
     */
    'pharmacy_id': string;
    /**
     * Location Identifier
     * @type {string}
     * @memberof RefillDto
     */
    'location_id': string;
    /**
     * Patient Record Location Id related to patient-service
     * @type {string}
     * @memberof RefillDto
     */
    'patient_id': string;
    /**
     * Order Id if this related to any order on order-service
     * @type {string}
     * @memberof RefillDto
     */
    'order_id'?: string;
    /**
     * 
     * @type {FulfillmentMethod}
     * @memberof RefillDto
     */
    'fulfillment_method': FulfillmentMethod;
    /**
     * 
     * @type {IngressMethod}
     * @memberof RefillDto
     */
    'ingress_method': IngressMethod;
    /**
     * True if the refill is urgent
     * @type {boolean}
     * @memberof RefillDto
     */
    'is_urgent'?: boolean;
    /**
     * True if the refill is required for today
     * @type {boolean}
     * @memberof RefillDto
     */
    'is_today'?: boolean;
    /**
     * Notes that may be displayed to pharmacist.
     * @type {string}
     * @memberof RefillDto
     */
    'notes'?: string;
    /**
     * Generate date when the refill is submitted.
     * @type {string}
     * @memberof RefillDto
     */
    'submitted_at': string;
    /**
     * Patient User Identifier (user-service)
     * @type {string}
     * @memberof RefillDto
     */
    'submitted_by': string;
    /**
     * Generate date when the refill is created.
     * @type {string}
     * @memberof RefillDto
     */
    'created_at': string;
    /**
     * Generate date when the refill is updated.
     * @type {string}
     * @memberof RefillDto
     */
    'updated_at': string;
    /**
     * List of Refill item prescription.
     * @type {Array<RefillItemDto>}
     * @memberof RefillDto
     */
    'refill_items': Array<RefillItemDto>;
}
/**
 * 
 * @export
 * @interface RefillItemDto
 */
export interface RefillItemDto {
    /**
     * Prescription Identifier
     * @type {string}
     * @memberof RefillItemDto
     */
    'refill_item_id': string;
    /**
     * Rx number of the prescription
     * @type {string}
     * @memberof RefillItemDto
     */
    'rx_number': string;
    /**
     * Prescription Identifier
     * @type {string}
     * @memberof RefillItemDto
     */
    'prescription_id': string;
    /**
     * Rx name of the prescription
     * @type {string}
     * @memberof RefillItemDto
     */
    'rx_name'?: string;
    /**
     * 
     * @type {RefillStatus}
     * @memberof RefillItemDto
     */
    'rx_status'?: RefillStatus;
    /**
     * More verbose message regarding refill status
     * @type {string}
     * @memberof RefillItemDto
     */
    'rx_status_message'?: string;
    /**
     * 
     * @type {RefillItemDtoRefill}
     * @memberof RefillItemDto
     */
    'refill'?: RefillItemDtoRefill;
    /**
     * 
     * @type {RefillItemDtoPrescription}
     * @memberof RefillItemDto
     */
    'prescription'?: RefillItemDtoPrescription;
}
/**
 * Prescription refilled reference
 * @export
 * @interface RefillItemDtoPrescription
 */
export interface RefillItemDtoPrescription {
    /**
     * Prescription Id generated from prescription-service
     * @type {string}
     * @memberof RefillItemDtoPrescription
     */
    'prescription_id': string;
    /**
     * Pharmacy Id referenced from pharmacy-service
     * @type {string}
     * @memberof RefillItemDtoPrescription
     */
    'pharmacy_id': string;
    /**
     * Location Id referenced from pharmacy-service
     * @type {string}
     * @memberof RefillItemDtoPrescription
     */
    'location_id': string;
    /**
     * LocationPatientRecordId referenced from patient-service
     * @type {string}
     * @memberof RefillItemDtoPrescription
     */
    'patient_id': string;
    /**
     * 
     * @type {string}
     * @memberof RefillItemDtoPrescription
     */
    'patient_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof RefillItemDtoPrescription
     */
    'patient_last_name': string;
    /**
     * 
     * @type {string}
     * @memberof RefillItemDtoPrescription
     */
    'patient_date_of_birth': string;
    /**
     * 
     * @type {string}
     * @memberof RefillItemDtoPrescription
     */
    'patient_gender'?: string;
    /**
     * 
     * @type {IntegrationType}
     * @memberof RefillItemDtoPrescription
     */
    'integration_type': IntegrationType;
    /**
     * 
     * @type {IngressMethod}
     * @memberof RefillItemDtoPrescription
     */
    'ingress_method'?: IngressMethod;
    /**
     * Rx Number of prescription from PMS system
     * @type {string}
     * @memberof RefillItemDtoPrescription
     */
    'rx_number': string;
    /**
     * Drug Name
     * @type {string}
     * @memberof RefillItemDtoPrescription
     */
    'drug_name'?: string;
    /**
     * Drug Brand
     * @type {string}
     * @memberof RefillItemDtoPrescription
     */
    'drug_brand'?: string;
    /**
     * FDA National Drug Code
     * @type {string}
     * @memberof RefillItemDtoPrescription
     */
    'ndc'?: string;
    /**
     * Drug Code Version
     * @type {string}
     * @memberof RefillItemDtoPrescription
     */
    'drug_code_ver'?: string;
    /**
     * Drug Code
     * @type {string}
     * @memberof RefillItemDtoPrescription
     */
    'drug_code'?: string;
    /**
     * Last Refill Date (YYYY-MM-DD)
     * @type {string}
     * @memberof RefillItemDtoPrescription
     */
    'last_refill_date'?: string;
    /**
     * Refills Remaining
     * @type {number}
     * @memberof RefillItemDtoPrescription
     */
    'remaining_refills'?: number;
    /**
     * Sig
     * @type {string}
     * @memberof RefillItemDtoPrescription
     */
    'sig'?: string;
    /**
     * 
     * @type {WorkflowStatus}
     * @memberof RefillItemDtoPrescription
     */
    'workflow_status'?: WorkflowStatus;
    /**
     * 
     * @type {RefillStatus}
     * @memberof RefillItemDtoPrescription
     */
    'refill_status'?: RefillStatus;
    /**
     * Date Written
     * @type {string}
     * @memberof RefillItemDtoPrescription
     */
    'written_date'?: string;
    /**
     * Date First Filled
     * @type {string}
     * @memberof RefillItemDtoPrescription
     */
    'first_filled_date'?: string;
    /**
     * Days Supply
     * @type {number}
     * @memberof RefillItemDtoPrescription
     */
    'days_supply'?: number;
    /**
     * Tracking Number
     * @type {string}
     * @memberof RefillItemDtoPrescription
     */
    'tracking_number'?: string;
    /**
     * 
     * @type {RxOrigin}
     * @memberof RefillItemDtoPrescription
     */
    'rx_origin'?: RxOrigin;
    /**
     * Flag to Determine if a generic was dispensed
     * @type {boolean}
     * @memberof RefillItemDtoPrescription
     */
    'is_generic_dispensed'?: boolean;
    /**
     * ICD Version
     * @type {string}
     * @memberof RefillItemDtoPrescription
     */
    'icd_version'?: string;
    /**
     * ICD Code
     * @type {string}
     * @memberof RefillItemDtoPrescription
     */
    'icd_code'?: string;
    /**
     * Flag to determine if the medication is PRN
     * @type {boolean}
     * @memberof RefillItemDtoPrescription
     */
    'is_prn'?: boolean;
    /**
     * Expiration Date (YYYYMMDD)
     * @type {string}
     * @memberof RefillItemDtoPrescription
     */
    'expiration_date'?: string;
    /**
     * Reassigned Rx Number
     * @type {string}
     * @memberof RefillItemDtoPrescription
     */
    'reassigned_rx_number'?: string;
    /**
     * Quantity On Hand
     * @type {number}
     * @memberof RefillItemDtoPrescription
     */
    'quantity_on_hand'?: number;
    /**
     * Co-Pay (amount)
     * @type {string}
     * @memberof RefillItemDtoPrescription
     */
    'co_pay'?: string;
    /**
     * Last Fulfillment Date (YYYYMMDD) medication was handed off to patient, whether pickup mail, delivery, etc
     * @type {string}
     * @memberof RefillItemDtoPrescription
     */
    'last_fulfillment_date'?: string;
    /**
     * 
     * @type {FulfillmentMethod}
     * @memberof RefillItemDtoPrescription
     */
    'last_fulfillment_method'?: FulfillmentMethod;
    /**
     * Last Refill Quantity
     * @type {number}
     * @memberof RefillItemDtoPrescription
     */
    'last_refill_quantity'?: number;
    /**
     * Original Refills Authorized
     * @type {number}
     * @memberof RefillItemDtoPrescription
     */
    'original_refills_authorized'?: number;
    /**
     * The number indicating which refill (ex. 1 of 3 total) for this prescription
     * @type {number}
     * @memberof RefillItemDtoPrescription
     */
    'refill_number'?: number;
    /**
     * Next Refill Date (YYYYMMDD)
     * @type {string}
     * @memberof RefillItemDtoPrescription
     */
    'next_refill_date'?: string;
    /**
     * Quantity Remaining
     * @type {number}
     * @memberof RefillItemDtoPrescription
     */
    'quantity_remaining'?: number;
    /**
     * Quantity Written
     * @type {number}
     * @memberof RefillItemDtoPrescription
     */
    'quantity_written'?: number;
    /**
     * Original Fill Quantity
     * @type {number}
     * @memberof RefillItemDtoPrescription
     */
    'original_fill_quantity'?: number;
    /**
     * Flag to determine if the prescription container has an easy to open cap
     * @type {boolean}
     * @memberof RefillItemDtoPrescription
     */
    'is_easy_open_cap'?: boolean;
    /**
     * Drug Scheduling as identified by the FDA
     * @type {string}
     * @memberof RefillItemDtoPrescription
     */
    'schedule'?: string;
    /**
     * Flag to indicate if the medication is intended for a pet
     * @type {boolean}
     * @memberof RefillItemDtoPrescription
     */
    'is_pet'?: boolean;
    /**
     * Flag to indicate if the medication is compounded
     * @type {boolean}
     * @memberof RefillItemDtoPrescription
     */
    'is_compounded'?: boolean;
    /**
     * Flag to indicate if the medication is over the counter
     * @type {boolean}
     * @memberof RefillItemDtoPrescription
     */
    'is_over_the_counter'?: boolean;
    /**
     * Flag to indicate if the medication may be enrolled into an auto refill program
     * @type {boolean}
     * @memberof RefillItemDtoPrescription
     */
    'is_auto_fill_allowed'?: boolean;
    /**
     * Flag to indicate if the medication is enrolled in an auto refill program. Note, this is only eligible to be true if is_auto_fill_allowed=true.
     * @type {boolean}
     * @memberof RefillItemDtoPrescription
     */
    'auto_fill_enrolled'?: boolean;
    /**
     * Determines if the prescription is active or not.
     * @type {boolean}
     * @memberof RefillItemDtoPrescription
     */
    'is_active': boolean;
    /**
     * Doctor First Name
     * @type {string}
     * @memberof RefillItemDtoPrescription
     */
    'doctor_first_name'?: string;
    /**
     * Doctor Last Name
     * @type {string}
     * @memberof RefillItemDtoPrescription
     */
    'doctor_last_name'?: string;
    /**
     * Doctor NPI Code
     * @type {string}
     * @memberof RefillItemDtoPrescription
     */
    'doctor_npi'?: string;
    /**
     * Doctor Phone Number
     * @type {string}
     * @memberof RefillItemDtoPrescription
     */
    'doctor_phone'?: string;
    /**
     * Doctor Fax Number
     * @type {string}
     * @memberof RefillItemDtoPrescription
     */
    'doctor_fax'?: string;
    /**
     * Doctor Address 1
     * @type {string}
     * @memberof RefillItemDtoPrescription
     */
    'doctor_address_1'?: string;
    /**
     * Doctor Address 2
     * @type {string}
     * @memberof RefillItemDtoPrescription
     */
    'doctor_address_2'?: string;
    /**
     * Doctor Address City
     * @type {string}
     * @memberof RefillItemDtoPrescription
     */
    'doctor_address_city'?: string;
    /**
     * Doctor Address State
     * @type {string}
     * @memberof RefillItemDtoPrescription
     */
    'doctor_address_state'?: string;
    /**
     * Doctor Address Zip Code
     * @type {string}
     * @memberof RefillItemDtoPrescription
     */
    'doctor_address_zip'?: string;
    /**
     * Doctor Address Country
     * @type {string}
     * @memberof RefillItemDtoPrescription
     */
    'doctor_address_country'?: string;
    /**
     * Doctor DEA Code
     * @type {string}
     * @memberof RefillItemDtoPrescription
     */
    'doctor_dea'?: string;
    /**
     * Doctor DEA State
     * @type {string}
     * @memberof RefillItemDtoPrescription
     */
    'doctor_dea_state'?: string;
    /**
     * 
     * @type {DoctorContactMethod}
     * @memberof RefillItemDtoPrescription
     */
    'doctor_preferred_contact_method'?: DoctorContactMethod;
    /**
     * Flag to determine if doctor authorize refill
     * @type {boolean}
     * @memberof RefillItemDtoPrescription
     */
    'doctor_refill_authorization_allowed'?: boolean;
    /**
     * Flag to determine if doctor allows to be contacted from patient
     * @type {boolean}
     * @memberof RefillItemDtoPrescription
     */
    'doctor_contact_prescriber_allowed'?: boolean;
    /**
     * Last Order Date (YYYY-MM-DD)
     * @type {string}
     * @memberof RefillItemDtoPrescription
     */
    'last_order_date'?: string;
    /**
     * 
     * @type {string}
     * @memberof RefillItemDtoPrescription
     */
    'created_at': string;
    /**
     * 
     * @type {string}
     * @memberof RefillItemDtoPrescription
     */
    'updated_at': string;
}
/**
 * Refill submit reference
 * @export
 * @interface RefillItemDtoRefill
 */
export interface RefillItemDtoRefill {
    /**
     * Refill Identifier
     * @type {string}
     * @memberof RefillItemDtoRefill
     */
    'refill_id': string;
    /**
     * Pharmacy Identifier
     * @type {string}
     * @memberof RefillItemDtoRefill
     */
    'pharmacy_id': string;
    /**
     * Location Identifier
     * @type {string}
     * @memberof RefillItemDtoRefill
     */
    'location_id': string;
    /**
     * Patient Record Location Id related to patient-service
     * @type {string}
     * @memberof RefillItemDtoRefill
     */
    'patient_id': string;
    /**
     * Order Id if this related to any order on order-service
     * @type {string}
     * @memberof RefillItemDtoRefill
     */
    'order_id'?: string;
    /**
     * 
     * @type {FulfillmentMethod}
     * @memberof RefillItemDtoRefill
     */
    'fulfillment_method': FulfillmentMethod;
    /**
     * 
     * @type {IngressMethod}
     * @memberof RefillItemDtoRefill
     */
    'ingress_method': IngressMethod;
    /**
     * True if the refill is urgent
     * @type {boolean}
     * @memberof RefillItemDtoRefill
     */
    'is_urgent'?: boolean;
    /**
     * True if the refill is required for today
     * @type {boolean}
     * @memberof RefillItemDtoRefill
     */
    'is_today'?: boolean;
    /**
     * Notes that may be displayed to pharmacist.
     * @type {string}
     * @memberof RefillItemDtoRefill
     */
    'notes'?: string;
    /**
     * Generate date when the refill is submitted.
     * @type {string}
     * @memberof RefillItemDtoRefill
     */
    'submitted_at': string;
    /**
     * Patient User Identifier (user-service)
     * @type {string}
     * @memberof RefillItemDtoRefill
     */
    'submitted_by': string;
    /**
     * Generate date when the refill is created.
     * @type {string}
     * @memberof RefillItemDtoRefill
     */
    'created_at': string;
    /**
     * Generate date when the refill is updated.
     * @type {string}
     * @memberof RefillItemDtoRefill
     */
    'updated_at': string;
    /**
     * List of Refill item prescription.
     * @type {Array<RefillItemDto>}
     * @memberof RefillItemDtoRefill
     */
    'refill_items': Array<RefillItemDto>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RefillStatus = {
    Success: 'success',
    NoRefills: 'no_refills',
    Expired: 'expired',
    TooSoon: 'too_soon',
    NotFound: 'not_found',
    Controlled: 'controlled',
    Narcotic: 'narcotic',
    Transferred: 'transferred',
    Discontinued: 'discontinued',
    OnHold: 'on_hold',
    Deleted: 'deleted',
    Fillable: 'fillable',
    PartiallyFilled: 'partially_filled',
    OutOfStock: 'out_of_stock',
    SpecialOrder: 'special_order',
    Deceased: 'deceased',
    RejectedReasonUnknown: 'rejected_reason_unknown'
} as const;

export type RefillStatus = typeof RefillStatus[keyof typeof RefillStatus];


/**
 * 
 * @export
 * @interface RefillSubmittedDto
 */
export interface RefillSubmittedDto {
    /**
     * Prescription Identifier
     * @type {string}
     * @memberof RefillSubmittedDto
     */
    'refill_item_id': string;
    /**
     * 
     * @type {RefillStatus}
     * @memberof RefillSubmittedDto
     */
    'rx_status'?: RefillStatus;
    /**
     * More verbose message regarding refill status
     * @type {string}
     * @memberof RefillSubmittedDto
     */
    'rx_status_message'?: string;
    /**
     * Prescription Id generated from prescription-service
     * @type {string}
     * @memberof RefillSubmittedDto
     */
    'prescription_id': string;
    /**
     * LocationPatientRecordId referenced from patient-service
     * @type {string}
     * @memberof RefillSubmittedDto
     */
    'patient_id': string;
    /**
     * 
     * @type {string}
     * @memberof RefillSubmittedDto
     */
    'patient_first_name': string;
    /**
     * 
     * @type {string}
     * @memberof RefillSubmittedDto
     */
    'patient_last_name': string;
    /**
     * Rx Number of prescription from PMS system
     * @type {string}
     * @memberof RefillSubmittedDto
     */
    'rx_number': string;
    /**
     * Drug Name
     * @type {string}
     * @memberof RefillSubmittedDto
     */
    'drug_name'?: string;
    /**
     * Refill Identifier
     * @type {string}
     * @memberof RefillSubmittedDto
     */
    'refill_id': string;
    /**
     * 
     * @type {FulfillmentMethod}
     * @memberof RefillSubmittedDto
     */
    'fulfillment_method': FulfillmentMethod;
    /**
     * 
     * @type {IngressMethod}
     * @memberof RefillSubmittedDto
     */
    'ingress_method': IngressMethod;
    /**
     * Notes that may be displayed to pharmacist.
     * @type {string}
     * @memberof RefillSubmittedDto
     */
    'notes'?: string;
    /**
     * Generate date when the refill is submitted.
     * @type {string}
     * @memberof RefillSubmittedDto
     */
    'submitted_at': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const RxOrigin = {
    Written: 'written',
    Phoned: 'phoned',
    Escribed: 'escribed'
} as const;

export type RxOrigin = typeof RxOrigin[keyof typeof RxOrigin];


/**
 * 
 * @export
 * @interface UpdateLocationConfigDto
 */
export interface UpdateLocationConfigDto {
    /**
     * 
     * @type {IntegrationType}
     * @memberof UpdateLocationConfigDto
     */
    'integration_type'?: IntegrationType;
    /**
     * Type of Fulfilment methods that this location supports.
     * @type {Array<FulfillmentMethod>}
     * @memberof UpdateLocationConfigDto
     */
    'fulfilment_methods'?: Array<FulfillmentMethod>;
}
/**
 * 
 * @export
 * @interface UpdatePrescriptionDto
 */
export interface UpdatePrescriptionDto {
    /**
     * Pharmacy Id referenced from pharmacy-service
     * @type {string}
     * @memberof UpdatePrescriptionDto
     */
    'pharmacy_id'?: string;
    /**
     * Location Id referenced from pharmacy-service
     * @type {string}
     * @memberof UpdatePrescriptionDto
     */
    'location_id'?: string;
    /**
     * LocationPatientRecordId referenced from patient-service
     * @type {string}
     * @memberof UpdatePrescriptionDto
     */
    'patient_id'?: string;
    /**
     * 
     * @type {IntegrationType}
     * @memberof UpdatePrescriptionDto
     */
    'integration_type'?: IntegrationType;
    /**
     * 
     * @type {IngressMethod}
     * @memberof UpdatePrescriptionDto
     */
    'ingress_method'?: IngressMethod;
    /**
     * Rx Number of prescription from PMS system
     * @type {string}
     * @memberof UpdatePrescriptionDto
     */
    'rx_number'?: string;
    /**
     * Drug Name
     * @type {string}
     * @memberof UpdatePrescriptionDto
     */
    'drug_name'?: string;
    /**
     * Drug Brand
     * @type {string}
     * @memberof UpdatePrescriptionDto
     */
    'drug_brand'?: string;
    /**
     * FDA National Drug Code
     * @type {string}
     * @memberof UpdatePrescriptionDto
     */
    'ndc'?: string;
    /**
     * Drug Code Version
     * @type {string}
     * @memberof UpdatePrescriptionDto
     */
    'drug_code_ver'?: string;
    /**
     * Drug Code
     * @type {string}
     * @memberof UpdatePrescriptionDto
     */
    'drug_code'?: string;
    /**
     * Last Refill Date (YYYY-MM-DD)
     * @type {string}
     * @memberof UpdatePrescriptionDto
     */
    'last_refill_date'?: string;
    /**
     * Refills Remaining
     * @type {number}
     * @memberof UpdatePrescriptionDto
     */
    'remaining_refills'?: number;
    /**
     * Sig
     * @type {string}
     * @memberof UpdatePrescriptionDto
     */
    'sig'?: string;
    /**
     * 
     * @type {WorkflowStatus}
     * @memberof UpdatePrescriptionDto
     */
    'workflow_status'?: WorkflowStatus;
    /**
     * 
     * @type {RefillStatus}
     * @memberof UpdatePrescriptionDto
     */
    'refill_status'?: RefillStatus;
    /**
     * Date Written
     * @type {string}
     * @memberof UpdatePrescriptionDto
     */
    'written_date'?: string;
    /**
     * Date First Filled
     * @type {string}
     * @memberof UpdatePrescriptionDto
     */
    'first_filled_date'?: string;
    /**
     * Days Supply
     * @type {number}
     * @memberof UpdatePrescriptionDto
     */
    'days_supply'?: number;
    /**
     * Tracking Number
     * @type {string}
     * @memberof UpdatePrescriptionDto
     */
    'tracking_number'?: string;
    /**
     * 
     * @type {RxOrigin}
     * @memberof UpdatePrescriptionDto
     */
    'rx_origin'?: RxOrigin;
    /**
     * Flag to Determine if a generic was dispensed
     * @type {boolean}
     * @memberof UpdatePrescriptionDto
     */
    'is_generic_dispensed'?: boolean;
    /**
     * ICD Version
     * @type {string}
     * @memberof UpdatePrescriptionDto
     */
    'icd_version'?: string;
    /**
     * ICD Code
     * @type {string}
     * @memberof UpdatePrescriptionDto
     */
    'icd_code'?: string;
    /**
     * Flag to determine if the medication is PRN
     * @type {boolean}
     * @memberof UpdatePrescriptionDto
     */
    'is_prn'?: boolean;
    /**
     * Expiration Date (YYYYMMDD)
     * @type {string}
     * @memberof UpdatePrescriptionDto
     */
    'expiration_date'?: string;
    /**
     * Reassigned Rx Number
     * @type {string}
     * @memberof UpdatePrescriptionDto
     */
    'reassigned_rx_number'?: string;
    /**
     * Quantity On Hand
     * @type {number}
     * @memberof UpdatePrescriptionDto
     */
    'quantity_on_hand'?: number;
    /**
     * Co-Pay (amount)
     * @type {string}
     * @memberof UpdatePrescriptionDto
     */
    'co_pay'?: string;
    /**
     * Last Fulfillment Date (YYYYMMDD) medication was handed off to patient, whether pickup mail, delivery, etc
     * @type {string}
     * @memberof UpdatePrescriptionDto
     */
    'last_fulfillment_date'?: string;
    /**
     * 
     * @type {FulfillmentMethod}
     * @memberof UpdatePrescriptionDto
     */
    'last_fulfillment_method'?: FulfillmentMethod;
    /**
     * Last Refill Quantity
     * @type {number}
     * @memberof UpdatePrescriptionDto
     */
    'last_refill_quantity'?: number;
    /**
     * Original Refills Authorized
     * @type {number}
     * @memberof UpdatePrescriptionDto
     */
    'original_refills_authorized'?: number;
    /**
     * The number indicating which refill (ex. 1 of 3 total) for this prescription
     * @type {number}
     * @memberof UpdatePrescriptionDto
     */
    'refill_number'?: number;
    /**
     * Next Refill Date (YYYYMMDD)
     * @type {string}
     * @memberof UpdatePrescriptionDto
     */
    'next_refill_date'?: string;
    /**
     * Quantity Remaining
     * @type {number}
     * @memberof UpdatePrescriptionDto
     */
    'quantity_remaining'?: number;
    /**
     * Quantity Written
     * @type {number}
     * @memberof UpdatePrescriptionDto
     */
    'quantity_written'?: number;
    /**
     * Original Fill Quantity
     * @type {number}
     * @memberof UpdatePrescriptionDto
     */
    'original_fill_quantity'?: number;
    /**
     * Flag to determine if the prescription container has an easy to open cap
     * @type {boolean}
     * @memberof UpdatePrescriptionDto
     */
    'is_easy_open_cap'?: boolean;
    /**
     * Drug Scheduling as identified by the FDA
     * @type {string}
     * @memberof UpdatePrescriptionDto
     */
    'schedule'?: string;
    /**
     * Flag to indicate if the medication is intended for a pet
     * @type {boolean}
     * @memberof UpdatePrescriptionDto
     */
    'is_pet'?: boolean;
    /**
     * Flag to indicate if the medication is compounded
     * @type {boolean}
     * @memberof UpdatePrescriptionDto
     */
    'is_compounded'?: boolean;
    /**
     * Flag to indicate if the medication is over the counter
     * @type {boolean}
     * @memberof UpdatePrescriptionDto
     */
    'is_over_the_counter'?: boolean;
    /**
     * Flag to indicate if the medication may be enrolled into an auto refill program
     * @type {boolean}
     * @memberof UpdatePrescriptionDto
     */
    'is_auto_fill_allowed'?: boolean;
    /**
     * Flag to indicate if the medication is enrolled in an auto refill program. Note, this is only eligible to be true if is_auto_fill_allowed=true.
     * @type {boolean}
     * @memberof UpdatePrescriptionDto
     */
    'auto_fill_enrolled'?: boolean;
    /**
     * Doctor First Name
     * @type {string}
     * @memberof UpdatePrescriptionDto
     */
    'doctor_first_name'?: string;
    /**
     * Doctor Last Name
     * @type {string}
     * @memberof UpdatePrescriptionDto
     */
    'doctor_last_name'?: string;
    /**
     * Doctor NPI Code
     * @type {string}
     * @memberof UpdatePrescriptionDto
     */
    'doctor_npi'?: string;
    /**
     * Doctor Phone Number
     * @type {string}
     * @memberof UpdatePrescriptionDto
     */
    'doctor_phone'?: string;
    /**
     * Doctor Fax Number
     * @type {string}
     * @memberof UpdatePrescriptionDto
     */
    'doctor_fax'?: string;
    /**
     * Doctor Address 1
     * @type {string}
     * @memberof UpdatePrescriptionDto
     */
    'doctor_address_1'?: string;
    /**
     * Doctor Address 2
     * @type {string}
     * @memberof UpdatePrescriptionDto
     */
    'doctor_address_2'?: string;
    /**
     * Doctor Address City
     * @type {string}
     * @memberof UpdatePrescriptionDto
     */
    'doctor_address_city'?: string;
    /**
     * Doctor Address State
     * @type {string}
     * @memberof UpdatePrescriptionDto
     */
    'doctor_address_state'?: string;
    /**
     * Doctor Address Zip Code
     * @type {string}
     * @memberof UpdatePrescriptionDto
     */
    'doctor_address_zip'?: string;
    /**
     * Doctor Address Country
     * @type {string}
     * @memberof UpdatePrescriptionDto
     */
    'doctor_address_country'?: string;
    /**
     * Doctor DEA Code
     * @type {string}
     * @memberof UpdatePrescriptionDto
     */
    'doctor_dea'?: string;
    /**
     * Doctor DEA State
     * @type {string}
     * @memberof UpdatePrescriptionDto
     */
    'doctor_dea_state'?: string;
    /**
     * 
     * @type {DoctorContactMethod}
     * @memberof UpdatePrescriptionDto
     */
    'doctor_preferred_contact_method'?: DoctorContactMethod;
    /**
     * Flag to determine if doctor authorize refill
     * @type {boolean}
     * @memberof UpdatePrescriptionDto
     */
    'doctor_refill_authorization_allowed'?: boolean;
    /**
     * Flag to determine if doctor allows to be contacted from patient
     * @type {boolean}
     * @memberof UpdatePrescriptionDto
     */
    'doctor_contact_prescriber_allowed'?: boolean;
    /**
     * Last Order Date (YYYY-MM-DD)
     * @type {string}
     * @memberof UpdatePrescriptionDto
     */
    'last_order_date'?: string;
    /**
     * Determines if the prescription is active or not.
     * @type {boolean}
     * @memberof UpdatePrescriptionDto
     */
    'is_active'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateRefillItemDto
 */
export interface UpdateRefillItemDto {
    /**
     * Rx number of the prescription
     * @type {string}
     * @memberof UpdateRefillItemDto
     */
    'rx_number': string;
    /**
     * 
     * @type {RefillStatus}
     * @memberof UpdateRefillItemDto
     */
    'rx_status'?: RefillStatus;
    /**
     * More verbose message regarding refill status
     * @type {string}
     * @memberof UpdateRefillItemDto
     */
    'rx_status_message'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const WorkflowStatus = {
    InProcess: 'in_process',
    ReadyForPickup: 'ready_for_pickup',
    ReadyForShipping: 'ready_for_shipping',
    ReadyForDelivery: 'ready_for_delivery',
    Mail: 'mail',
    Shipped: 'shipped',
    DeliveryInProgress: 'delivery_in_progress',
    Dispensed: 'dispensed',
    Returned: 'returned',
    Delivered: 'delivered',
    Cancelled: 'cancelled',
    Transferred: 'transferred',
    Expired: 'expired',
    Discontinued: 'discontinued',
    InQueue: 'in_queue',
    PartialFill: 'partial_fill',
    PartiallyFilled: 'partially_filled',
    OutOfStock: 'out_of_stock',
    SpecialOrder: 'special_order',
    AwaitingInsuranceAuthorization: 'awaiting_insurance_authorization',
    AwaitingPrescriberAuthorization: 'awaiting_prescriber_authorization',
    InsuranceRefused: 'insurance_refused',
    PrescriberRefused: 'prescriber_refused',
    ReturnedToStock: 'returned_to_stock'
} as const;

export type WorkflowStatus = typeof WorkflowStatus[keyof typeof WorkflowStatus];



/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get health status of current service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheckGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health-check`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get health status of current service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async healthCheckGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HealthStatus>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.healthCheckGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Get health status of current service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheckGet(options?: any): AxiosPromise<HealthStatus> {
            return localVarFp.healthCheckGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - interface
 * @export
 * @interface DefaultApi
 */
export interface DefaultApiInterface {
    /**
     * 
     * @summary Get health status of current service.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    healthCheckGet(options?: AxiosRequestConfig): AxiosPromise<HealthStatus>;

}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI implements DefaultApiInterface {
    /**
     * 
     * @summary Get health status of current service.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public healthCheckGet(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).healthCheckGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IntegrationApi - axios parameter creator
 * @export
 */
export const IntegrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create or Update location config
         * @param {string} locationId 
         * @param {UpdateLocationConfigDto} updateLocationConfigDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationConfigCreate: async (locationId: string, updateLocationConfigDto: UpdateLocationConfigDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('locationConfigCreate', 'locationId', locationId)
            // verify required parameter 'updateLocationConfigDto' is not null or undefined
            assertParamExists('locationConfigCreate', 'updateLocationConfigDto', updateLocationConfigDto)
            const localVarPath = `/locations/{location_id}/config`
                .replace(`{${"location_id"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLocationConfigDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get location config by location_id
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationConfigFind: async (locationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('locationConfigFind', 'locationId', locationId)
            const localVarPath = `/locations/{location_id}/config`
                .replace(`{${"location_id"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete forever a location config by location_id
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationConfigRemove: async (locationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('locationConfigRemove', 'locationId', locationId)
            const localVarPath = `/locations/{location_id}/config`
                .replace(`{${"location_id"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IntegrationApi - functional programming interface
 * @export
 */
export const IntegrationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IntegrationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create or Update location config
         * @param {string} locationId 
         * @param {UpdateLocationConfigDto} updateLocationConfigDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locationConfigCreate(locationId: string, updateLocationConfigDto: UpdateLocationConfigDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationConfigDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locationConfigCreate(locationId, updateLocationConfigDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get location config by location_id
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locationConfigFind(locationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationConfigDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locationConfigFind(locationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete forever a location config by location_id
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async locationConfigRemove(locationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationConfigDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.locationConfigRemove(locationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IntegrationApi - factory interface
 * @export
 */
export const IntegrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IntegrationApiFp(configuration)
    return {
        /**
         * 
         * @summary Create or Update location config
         * @param {string} locationId 
         * @param {UpdateLocationConfigDto} updateLocationConfigDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationConfigCreate(locationId: string, updateLocationConfigDto: UpdateLocationConfigDto, options?: any): AxiosPromise<LocationConfigDto> {
            return localVarFp.locationConfigCreate(locationId, updateLocationConfigDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get location config by location_id
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationConfigFind(locationId: string, options?: any): AxiosPromise<LocationConfigDto> {
            return localVarFp.locationConfigFind(locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete forever a location config by location_id
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationConfigRemove(locationId: string, options?: any): AxiosPromise<LocationConfigDto> {
            return localVarFp.locationConfigRemove(locationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IntegrationApi - interface
 * @export
 * @interface IntegrationApi
 */
export interface IntegrationApiInterface {
    /**
     * 
     * @summary Create or Update location config
     * @param {string} locationId 
     * @param {UpdateLocationConfigDto} updateLocationConfigDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApiInterface
     */
    locationConfigCreate(locationId: string, updateLocationConfigDto: UpdateLocationConfigDto, options?: AxiosRequestConfig): AxiosPromise<LocationConfigDto>;

    /**
     * 
     * @summary Get location config by location_id
     * @param {string} locationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApiInterface
     */
    locationConfigFind(locationId: string, options?: AxiosRequestConfig): AxiosPromise<LocationConfigDto>;

    /**
     * 
     * @summary Delete forever a location config by location_id
     * @param {string} locationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApiInterface
     */
    locationConfigRemove(locationId: string, options?: AxiosRequestConfig): AxiosPromise<LocationConfigDto>;

}

/**
 * IntegrationApi - object-oriented interface
 * @export
 * @class IntegrationApi
 * @extends {BaseAPI}
 */
export class IntegrationApi extends BaseAPI implements IntegrationApiInterface {
    /**
     * 
     * @summary Create or Update location config
     * @param {string} locationId 
     * @param {UpdateLocationConfigDto} updateLocationConfigDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public locationConfigCreate(locationId: string, updateLocationConfigDto: UpdateLocationConfigDto, options?: AxiosRequestConfig) {
        return IntegrationApiFp(this.configuration).locationConfigCreate(locationId, updateLocationConfigDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get location config by location_id
     * @param {string} locationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public locationConfigFind(locationId: string, options?: AxiosRequestConfig) {
        return IntegrationApiFp(this.configuration).locationConfigFind(locationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete forever a location config by location_id
     * @param {string} locationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public locationConfigRemove(locationId: string, options?: AxiosRequestConfig) {
        return IntegrationApiFp(this.configuration).locationConfigRemove(locationId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PatientApi - axios parameter creator
 * @export
 */
export const PatientApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} patientId Patient Record Identifier
         * @param {string} locationId 
         * @param {CreatePrescriptionDto} createPrescriptionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prescriptionCreateOrUpdate: async (patientId: string, locationId: string, createPrescriptionDto: CreatePrescriptionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('prescriptionCreateOrUpdate', 'patientId', patientId)
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('prescriptionCreateOrUpdate', 'locationId', locationId)
            // verify required parameter 'createPrescriptionDto' is not null or undefined
            assertParamExists('prescriptionCreateOrUpdate', 'createPrescriptionDto', createPrescriptionDto)
            const localVarPath = `/locations/{location_id}/patients/{patient_id}/prescriptions`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"location_id"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createPrescriptionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientId Patient Record Identifier
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prescriptionFindAll: async (patientId: string, locationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('prescriptionFindAll', 'patientId', patientId)
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('prescriptionFindAll', 'locationId', locationId)
            const localVarPath = `/locations/{location_id}/patients/{patient_id}/prescriptions`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"location_id"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} idOrRx 
         * @param {string} patientId Patient Record Identifier
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prescriptionFindOne: async (idOrRx: string, patientId: string, locationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'idOrRx' is not null or undefined
            assertParamExists('prescriptionFindOne', 'idOrRx', idOrRx)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('prescriptionFindOne', 'patientId', patientId)
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('prescriptionFindOne', 'locationId', locationId)
            const localVarPath = `/locations/{location_id}/patients/{patient_id}/prescriptions/{id_or_rx}`
                .replace(`{${"id_or_rx"}}`, encodeURIComponent(String(idOrRx)))
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"location_id"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get Prescription information from PMS / DataFeed depending how to location is configured.       When location is PMS integrated then also ensure to create or update Patient and Prescription data to our system.       When location is DataFeed then query only the database for data. The patient/prescription data should be already on our system
         * @summary Get prescription by patient identifiers
         * @param {string} rxNumber 
         * @param {string} lastName 
         * @param {string} dateOfBirth 
         * @param {string} locationId 
         * @param {string} [xPharmacyId] This header should be present when location is PMS integrated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prescriptionGetByRx: async (rxNumber: string, lastName: string, dateOfBirth: string, locationId: string, xPharmacyId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'rxNumber' is not null or undefined
            assertParamExists('prescriptionGetByRx', 'rxNumber', rxNumber)
            // verify required parameter 'lastName' is not null or undefined
            assertParamExists('prescriptionGetByRx', 'lastName', lastName)
            // verify required parameter 'dateOfBirth' is not null or undefined
            assertParamExists('prescriptionGetByRx', 'dateOfBirth', dateOfBirth)
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('prescriptionGetByRx', 'locationId', locationId)
            const localVarPath = `/locations/{location_id}/prescriptions`
                .replace(`{${"location_id"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (rxNumber !== undefined) {
                localVarQueryParameter['rx_number'] = rxNumber;
            }

            if (lastName !== undefined) {
                localVarQueryParameter['last_name'] = lastName;
            }

            if (dateOfBirth !== undefined) {
                localVarQueryParameter['date_of_birth'] = dateOfBirth;
            }

            if (xPharmacyId != null) {
                localVarHeaderParameter['x-pharmacy-id'] = String(xPharmacyId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} prescriptionId 
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prescriptionHistoryFindAll: async (prescriptionId: string, locationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prescriptionId' is not null or undefined
            assertParamExists('prescriptionHistoryFindAll', 'prescriptionId', prescriptionId)
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('prescriptionHistoryFindAll', 'locationId', locationId)
            const localVarPath = `/locations/{location_id}/prescriptions/{prescription_id}/history`
                .replace(`{${"prescription_id"}}`, encodeURIComponent(String(prescriptionId)))
                .replace(`{${"location_id"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} patientId Patient Record Identifier
         * @param {string} locationId 
         * @param {MigratePrescriptionDto} migratePrescriptionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prescriptionMigrate: async (patientId: string, locationId: string, migratePrescriptionDto: MigratePrescriptionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('prescriptionMigrate', 'patientId', patientId)
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('prescriptionMigrate', 'locationId', locationId)
            // verify required parameter 'migratePrescriptionDto' is not null or undefined
            assertParamExists('prescriptionMigrate', 'migratePrescriptionDto', migratePrescriptionDto)
            const localVarPath = `/locations/{location_id}/patients/{patient_id}/prescriptions/migrate`
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"location_id"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(migratePrescriptionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} prescriptionId 
         * @param {string} patientId Patient Record Identifier
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prescriptionRemove: async (prescriptionId: string, patientId: string, locationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prescriptionId' is not null or undefined
            assertParamExists('prescriptionRemove', 'prescriptionId', prescriptionId)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('prescriptionRemove', 'patientId', patientId)
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('prescriptionRemove', 'locationId', locationId)
            const localVarPath = `/locations/{location_id}/patients/{patient_id}/prescriptions/{prescription_id}`
                .replace(`{${"prescription_id"}}`, encodeURIComponent(String(prescriptionId)))
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"location_id"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} prescriptionId 
         * @param {string} patientId Patient Record Identifier
         * @param {string} locationId 
         * @param {UpdatePrescriptionDto} updatePrescriptionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prescriptionUpdate: async (prescriptionId: string, patientId: string, locationId: string, updatePrescriptionDto: UpdatePrescriptionDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'prescriptionId' is not null or undefined
            assertParamExists('prescriptionUpdate', 'prescriptionId', prescriptionId)
            // verify required parameter 'patientId' is not null or undefined
            assertParamExists('prescriptionUpdate', 'patientId', patientId)
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('prescriptionUpdate', 'locationId', locationId)
            // verify required parameter 'updatePrescriptionDto' is not null or undefined
            assertParamExists('prescriptionUpdate', 'updatePrescriptionDto', updatePrescriptionDto)
            const localVarPath = `/locations/{location_id}/patients/{patient_id}/prescriptions/{prescription_id}`
                .replace(`{${"prescription_id"}}`, encodeURIComponent(String(prescriptionId)))
                .replace(`{${"patient_id"}}`, encodeURIComponent(String(patientId)))
                .replace(`{${"location_id"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePrescriptionDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Submit a refill for multiple prescription
         * @param {string} locationId 
         * @param {CreateRefillDto} createRefillDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillsCreate: async (locationId: string, createRefillDto: CreateRefillDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('refillsCreate', 'locationId', locationId)
            // verify required parameter 'createRefillDto' is not null or undefined
            assertParamExists('refillsCreate', 'createRefillDto', createRefillDto)
            const localVarPath = `/locations/{location_id}/refills`
                .replace(`{${"location_id"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createRefillDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} locationId 
         * @param {Array<string>} [patientId] Array of Patient ids
         * @param {string} [submittedBy] 
         * @param {string} [orderId] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [sort] 
         * @param {boolean} [includeRefillItems] 
         * @param {boolean} [includePrescriptions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillsFind: async (locationId: string, patientId?: Array<string>, submittedBy?: string, orderId?: string, limit?: number, offset?: number, sort?: string, includeRefillItems?: boolean, includePrescriptions?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('refillsFind', 'locationId', locationId)
            const localVarPath = `/locations/{location_id}/refills`
                .replace(`{${"location_id"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (patientId) {
                localVarQueryParameter['patient_id'] = patientId;
            }

            if (submittedBy !== undefined) {
                localVarQueryParameter['submitted_by'] = submittedBy;
            }

            if (orderId !== undefined) {
                localVarQueryParameter['order_id'] = orderId;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (includeRefillItems !== undefined) {
                localVarQueryParameter['include_refill_items'] = includeRefillItems;
            }

            if (includePrescriptions !== undefined) {
                localVarQueryParameter['include_prescriptions'] = includePrescriptions;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} refillId 
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillsFindOne: async (refillId: string, locationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refillId' is not null or undefined
            assertParamExists('refillsFindOne', 'refillId', refillId)
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('refillsFindOne', 'locationId', locationId)
            const localVarPath = `/locations/{location_id}/refills/{refill_id}`
                .replace(`{${"refill_id"}}`, encodeURIComponent(String(refillId)))
                .replace(`{${"location_id"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Submit a refill for multiple prescription
         * @param {string} locationId 
         * @param {MigrateRefillDto} migrateRefillDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillsMigrate: async (locationId: string, migrateRefillDto: MigrateRefillDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('refillsMigrate', 'locationId', locationId)
            // verify required parameter 'migrateRefillDto' is not null or undefined
            assertParamExists('refillsMigrate', 'migrateRefillDto', migrateRefillDto)
            const localVarPath = `/locations/{location_id}/refills/migrate`
                .replace(`{${"location_id"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(migrateRefillDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} refillId 
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillsRemove: async (refillId: string, locationId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'refillId' is not null or undefined
            assertParamExists('refillsRemove', 'refillId', refillId)
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('refillsRemove', 'locationId', locationId)
            const localVarPath = `/locations/{location_id}/refills/{refill_id}`
                .replace(`{${"refill_id"}}`, encodeURIComponent(String(refillId)))
                .replace(`{${"location_id"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PatientApi - functional programming interface
 * @export
 */
export const PatientApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PatientApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} patientId Patient Record Identifier
         * @param {string} locationId 
         * @param {CreatePrescriptionDto} createPrescriptionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prescriptionCreateOrUpdate(patientId: string, locationId: string, createPrescriptionDto: CreatePrescriptionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrescriptionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.prescriptionCreateOrUpdate(patientId, locationId, createPrescriptionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientId Patient Record Identifier
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prescriptionFindAll(patientId: string, locationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PrescriptionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.prescriptionFindAll(patientId, locationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} idOrRx 
         * @param {string} patientId Patient Record Identifier
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prescriptionFindOne(idOrRx: string, patientId: string, locationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrescriptionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.prescriptionFindOne(idOrRx, patientId, locationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get Prescription information from PMS / DataFeed depending how to location is configured.       When location is PMS integrated then also ensure to create or update Patient and Prescription data to our system.       When location is DataFeed then query only the database for data. The patient/prescription data should be already on our system
         * @summary Get prescription by patient identifiers
         * @param {string} rxNumber 
         * @param {string} lastName 
         * @param {string} dateOfBirth 
         * @param {string} locationId 
         * @param {string} [xPharmacyId] This header should be present when location is PMS integrated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prescriptionGetByRx(rxNumber: string, lastName: string, dateOfBirth: string, locationId: string, xPharmacyId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrescriptionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.prescriptionGetByRx(rxNumber, lastName, dateOfBirth, locationId, xPharmacyId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} prescriptionId 
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prescriptionHistoryFindAll(prescriptionId: string, locationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PrescriptionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.prescriptionHistoryFindAll(prescriptionId, locationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} patientId Patient Record Identifier
         * @param {string} locationId 
         * @param {MigratePrescriptionDto} migratePrescriptionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prescriptionMigrate(patientId: string, locationId: string, migratePrescriptionDto: MigratePrescriptionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrescriptionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.prescriptionMigrate(patientId, locationId, migratePrescriptionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} prescriptionId 
         * @param {string} patientId Patient Record Identifier
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prescriptionRemove(prescriptionId: string, patientId: string, locationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrescriptionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.prescriptionRemove(prescriptionId, patientId, locationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} prescriptionId 
         * @param {string} patientId Patient Record Identifier
         * @param {string} locationId 
         * @param {UpdatePrescriptionDto} updatePrescriptionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async prescriptionUpdate(prescriptionId: string, patientId: string, locationId: string, updatePrescriptionDto: UpdatePrescriptionDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrescriptionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.prescriptionUpdate(prescriptionId, patientId, locationId, updatePrescriptionDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Submit a refill for multiple prescription
         * @param {string} locationId 
         * @param {CreateRefillDto} createRefillDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refillsCreate(locationId: string, createRefillDto: CreateRefillDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RefillDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refillsCreate(locationId, createRefillDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} locationId 
         * @param {Array<string>} [patientId] Array of Patient ids
         * @param {string} [submittedBy] 
         * @param {string} [orderId] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [sort] 
         * @param {boolean} [includeRefillItems] 
         * @param {boolean} [includePrescriptions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refillsFind(locationId: string, patientId?: Array<string>, submittedBy?: string, orderId?: string, limit?: number, offset?: number, sort?: string, includeRefillItems?: boolean, includePrescriptions?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RefillDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refillsFind(locationId, patientId, submittedBy, orderId, limit, offset, sort, includeRefillItems, includePrescriptions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} refillId 
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refillsFindOne(refillId: string, locationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RefillDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refillsFindOne(refillId, locationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Submit a refill for multiple prescription
         * @param {string} locationId 
         * @param {MigrateRefillDto} migrateRefillDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refillsMigrate(locationId: string, migrateRefillDto: MigrateRefillDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RefillDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refillsMigrate(locationId, migrateRefillDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} refillId 
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refillsRemove(refillId: string, locationId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RefillDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refillsRemove(refillId, locationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PatientApi - factory interface
 * @export
 */
export const PatientApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PatientApiFp(configuration)
    return {
        /**
         * 
         * @param {string} patientId Patient Record Identifier
         * @param {string} locationId 
         * @param {CreatePrescriptionDto} createPrescriptionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prescriptionCreateOrUpdate(patientId: string, locationId: string, createPrescriptionDto: CreatePrescriptionDto, options?: any): AxiosPromise<PrescriptionDto> {
            return localVarFp.prescriptionCreateOrUpdate(patientId, locationId, createPrescriptionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientId Patient Record Identifier
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prescriptionFindAll(patientId: string, locationId: string, options?: any): AxiosPromise<Array<PrescriptionDto>> {
            return localVarFp.prescriptionFindAll(patientId, locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} idOrRx 
         * @param {string} patientId Patient Record Identifier
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prescriptionFindOne(idOrRx: string, patientId: string, locationId: string, options?: any): AxiosPromise<PrescriptionDto> {
            return localVarFp.prescriptionFindOne(idOrRx, patientId, locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get Prescription information from PMS / DataFeed depending how to location is configured.       When location is PMS integrated then also ensure to create or update Patient and Prescription data to our system.       When location is DataFeed then query only the database for data. The patient/prescription data should be already on our system
         * @summary Get prescription by patient identifiers
         * @param {string} rxNumber 
         * @param {string} lastName 
         * @param {string} dateOfBirth 
         * @param {string} locationId 
         * @param {string} [xPharmacyId] This header should be present when location is PMS integrated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prescriptionGetByRx(rxNumber: string, lastName: string, dateOfBirth: string, locationId: string, xPharmacyId?: string, options?: any): AxiosPromise<PrescriptionDto> {
            return localVarFp.prescriptionGetByRx(rxNumber, lastName, dateOfBirth, locationId, xPharmacyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} prescriptionId 
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prescriptionHistoryFindAll(prescriptionId: string, locationId: string, options?: any): AxiosPromise<Array<PrescriptionDto>> {
            return localVarFp.prescriptionHistoryFindAll(prescriptionId, locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} patientId Patient Record Identifier
         * @param {string} locationId 
         * @param {MigratePrescriptionDto} migratePrescriptionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prescriptionMigrate(patientId: string, locationId: string, migratePrescriptionDto: MigratePrescriptionDto, options?: any): AxiosPromise<PrescriptionDto> {
            return localVarFp.prescriptionMigrate(patientId, locationId, migratePrescriptionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} prescriptionId 
         * @param {string} patientId Patient Record Identifier
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prescriptionRemove(prescriptionId: string, patientId: string, locationId: string, options?: any): AxiosPromise<PrescriptionDto> {
            return localVarFp.prescriptionRemove(prescriptionId, patientId, locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} prescriptionId 
         * @param {string} patientId Patient Record Identifier
         * @param {string} locationId 
         * @param {UpdatePrescriptionDto} updatePrescriptionDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prescriptionUpdate(prescriptionId: string, patientId: string, locationId: string, updatePrescriptionDto: UpdatePrescriptionDto, options?: any): AxiosPromise<PrescriptionDto> {
            return localVarFp.prescriptionUpdate(prescriptionId, patientId, locationId, updatePrescriptionDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Submit a refill for multiple prescription
         * @param {string} locationId 
         * @param {CreateRefillDto} createRefillDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillsCreate(locationId: string, createRefillDto: CreateRefillDto, options?: any): AxiosPromise<RefillDto> {
            return localVarFp.refillsCreate(locationId, createRefillDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} locationId 
         * @param {Array<string>} [patientId] Array of Patient ids
         * @param {string} [submittedBy] 
         * @param {string} [orderId] 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [sort] 
         * @param {boolean} [includeRefillItems] 
         * @param {boolean} [includePrescriptions] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillsFind(locationId: string, patientId?: Array<string>, submittedBy?: string, orderId?: string, limit?: number, offset?: number, sort?: string, includeRefillItems?: boolean, includePrescriptions?: boolean, options?: any): AxiosPromise<Array<RefillDto>> {
            return localVarFp.refillsFind(locationId, patientId, submittedBy, orderId, limit, offset, sort, includeRefillItems, includePrescriptions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} refillId 
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillsFindOne(refillId: string, locationId: string, options?: any): AxiosPromise<RefillDto> {
            return localVarFp.refillsFindOne(refillId, locationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Submit a refill for multiple prescription
         * @param {string} locationId 
         * @param {MigrateRefillDto} migrateRefillDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillsMigrate(locationId: string, migrateRefillDto: MigrateRefillDto, options?: any): AxiosPromise<RefillDto> {
            return localVarFp.refillsMigrate(locationId, migrateRefillDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} refillId 
         * @param {string} locationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillsRemove(refillId: string, locationId: string, options?: any): AxiosPromise<RefillDto> {
            return localVarFp.refillsRemove(refillId, locationId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PatientApi - interface
 * @export
 * @interface PatientApi
 */
export interface PatientApiInterface {
    /**
     * 
     * @param {string} patientId Patient Record Identifier
     * @param {string} locationId 
     * @param {CreatePrescriptionDto} createPrescriptionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApiInterface
     */
    prescriptionCreateOrUpdate(patientId: string, locationId: string, createPrescriptionDto: CreatePrescriptionDto, options?: AxiosRequestConfig): AxiosPromise<PrescriptionDto>;

    /**
     * 
     * @param {string} patientId Patient Record Identifier
     * @param {string} locationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApiInterface
     */
    prescriptionFindAll(patientId: string, locationId: string, options?: AxiosRequestConfig): AxiosPromise<Array<PrescriptionDto>>;

    /**
     * 
     * @param {string} idOrRx 
     * @param {string} patientId Patient Record Identifier
     * @param {string} locationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApiInterface
     */
    prescriptionFindOne(idOrRx: string, patientId: string, locationId: string, options?: AxiosRequestConfig): AxiosPromise<PrescriptionDto>;

    /**
     * Get Prescription information from PMS / DataFeed depending how to location is configured.       When location is PMS integrated then also ensure to create or update Patient and Prescription data to our system.       When location is DataFeed then query only the database for data. The patient/prescription data should be already on our system
     * @summary Get prescription by patient identifiers
     * @param {string} rxNumber 
     * @param {string} lastName 
     * @param {string} dateOfBirth 
     * @param {string} locationId 
     * @param {string} [xPharmacyId] This header should be present when location is PMS integrated.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApiInterface
     */
    prescriptionGetByRx(rxNumber: string, lastName: string, dateOfBirth: string, locationId: string, xPharmacyId?: string, options?: AxiosRequestConfig): AxiosPromise<PrescriptionDto>;

    /**
     * 
     * @param {string} prescriptionId 
     * @param {string} locationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApiInterface
     */
    prescriptionHistoryFindAll(prescriptionId: string, locationId: string, options?: AxiosRequestConfig): AxiosPromise<Array<PrescriptionDto>>;

    /**
     * 
     * @param {string} patientId Patient Record Identifier
     * @param {string} locationId 
     * @param {MigratePrescriptionDto} migratePrescriptionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApiInterface
     */
    prescriptionMigrate(patientId: string, locationId: string, migratePrescriptionDto: MigratePrescriptionDto, options?: AxiosRequestConfig): AxiosPromise<PrescriptionDto>;

    /**
     * 
     * @param {string} prescriptionId 
     * @param {string} patientId Patient Record Identifier
     * @param {string} locationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApiInterface
     */
    prescriptionRemove(prescriptionId: string, patientId: string, locationId: string, options?: AxiosRequestConfig): AxiosPromise<PrescriptionDto>;

    /**
     * 
     * @param {string} prescriptionId 
     * @param {string} patientId Patient Record Identifier
     * @param {string} locationId 
     * @param {UpdatePrescriptionDto} updatePrescriptionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApiInterface
     */
    prescriptionUpdate(prescriptionId: string, patientId: string, locationId: string, updatePrescriptionDto: UpdatePrescriptionDto, options?: AxiosRequestConfig): AxiosPromise<PrescriptionDto>;

    /**
     * 
     * @summary Submit a refill for multiple prescription
     * @param {string} locationId 
     * @param {CreateRefillDto} createRefillDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApiInterface
     */
    refillsCreate(locationId: string, createRefillDto: CreateRefillDto, options?: AxiosRequestConfig): AxiosPromise<RefillDto>;

    /**
     * 
     * @param {string} locationId 
     * @param {Array<string>} [patientId] Array of Patient ids
     * @param {string} [submittedBy] 
     * @param {string} [orderId] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {string} [sort] 
     * @param {boolean} [includeRefillItems] 
     * @param {boolean} [includePrescriptions] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApiInterface
     */
    refillsFind(locationId: string, patientId?: Array<string>, submittedBy?: string, orderId?: string, limit?: number, offset?: number, sort?: string, includeRefillItems?: boolean, includePrescriptions?: boolean, options?: AxiosRequestConfig): AxiosPromise<Array<RefillDto>>;

    /**
     * 
     * @param {string} refillId 
     * @param {string} locationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApiInterface
     */
    refillsFindOne(refillId: string, locationId: string, options?: AxiosRequestConfig): AxiosPromise<RefillDto>;

    /**
     * 
     * @summary Submit a refill for multiple prescription
     * @param {string} locationId 
     * @param {MigrateRefillDto} migrateRefillDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApiInterface
     */
    refillsMigrate(locationId: string, migrateRefillDto: MigrateRefillDto, options?: AxiosRequestConfig): AxiosPromise<RefillDto>;

    /**
     * 
     * @param {string} refillId 
     * @param {string} locationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApiInterface
     */
    refillsRemove(refillId: string, locationId: string, options?: AxiosRequestConfig): AxiosPromise<RefillDto>;

}

/**
 * PatientApi - object-oriented interface
 * @export
 * @class PatientApi
 * @extends {BaseAPI}
 */
export class PatientApi extends BaseAPI implements PatientApiInterface {
    /**
     * 
     * @param {string} patientId Patient Record Identifier
     * @param {string} locationId 
     * @param {CreatePrescriptionDto} createPrescriptionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public prescriptionCreateOrUpdate(patientId: string, locationId: string, createPrescriptionDto: CreatePrescriptionDto, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).prescriptionCreateOrUpdate(patientId, locationId, createPrescriptionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientId Patient Record Identifier
     * @param {string} locationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public prescriptionFindAll(patientId: string, locationId: string, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).prescriptionFindAll(patientId, locationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} idOrRx 
     * @param {string} patientId Patient Record Identifier
     * @param {string} locationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public prescriptionFindOne(idOrRx: string, patientId: string, locationId: string, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).prescriptionFindOne(idOrRx, patientId, locationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get Prescription information from PMS / DataFeed depending how to location is configured.       When location is PMS integrated then also ensure to create or update Patient and Prescription data to our system.       When location is DataFeed then query only the database for data. The patient/prescription data should be already on our system
     * @summary Get prescription by patient identifiers
     * @param {string} rxNumber 
     * @param {string} lastName 
     * @param {string} dateOfBirth 
     * @param {string} locationId 
     * @param {string} [xPharmacyId] This header should be present when location is PMS integrated.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public prescriptionGetByRx(rxNumber: string, lastName: string, dateOfBirth: string, locationId: string, xPharmacyId?: string, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).prescriptionGetByRx(rxNumber, lastName, dateOfBirth, locationId, xPharmacyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} prescriptionId 
     * @param {string} locationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public prescriptionHistoryFindAll(prescriptionId: string, locationId: string, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).prescriptionHistoryFindAll(prescriptionId, locationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} patientId Patient Record Identifier
     * @param {string} locationId 
     * @param {MigratePrescriptionDto} migratePrescriptionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public prescriptionMigrate(patientId: string, locationId: string, migratePrescriptionDto: MigratePrescriptionDto, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).prescriptionMigrate(patientId, locationId, migratePrescriptionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} prescriptionId 
     * @param {string} patientId Patient Record Identifier
     * @param {string} locationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public prescriptionRemove(prescriptionId: string, patientId: string, locationId: string, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).prescriptionRemove(prescriptionId, patientId, locationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} prescriptionId 
     * @param {string} patientId Patient Record Identifier
     * @param {string} locationId 
     * @param {UpdatePrescriptionDto} updatePrescriptionDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public prescriptionUpdate(prescriptionId: string, patientId: string, locationId: string, updatePrescriptionDto: UpdatePrescriptionDto, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).prescriptionUpdate(prescriptionId, patientId, locationId, updatePrescriptionDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Submit a refill for multiple prescription
     * @param {string} locationId 
     * @param {CreateRefillDto} createRefillDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public refillsCreate(locationId: string, createRefillDto: CreateRefillDto, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).refillsCreate(locationId, createRefillDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} locationId 
     * @param {Array<string>} [patientId] Array of Patient ids
     * @param {string} [submittedBy] 
     * @param {string} [orderId] 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {string} [sort] 
     * @param {boolean} [includeRefillItems] 
     * @param {boolean} [includePrescriptions] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public refillsFind(locationId: string, patientId?: Array<string>, submittedBy?: string, orderId?: string, limit?: number, offset?: number, sort?: string, includeRefillItems?: boolean, includePrescriptions?: boolean, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).refillsFind(locationId, patientId, submittedBy, orderId, limit, offset, sort, includeRefillItems, includePrescriptions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} refillId 
     * @param {string} locationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public refillsFindOne(refillId: string, locationId: string, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).refillsFindOne(refillId, locationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Submit a refill for multiple prescription
     * @param {string} locationId 
     * @param {MigrateRefillDto} migrateRefillDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public refillsMigrate(locationId: string, migrateRefillDto: MigrateRefillDto, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).refillsMigrate(locationId, migrateRefillDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} refillId 
     * @param {string} locationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    public refillsRemove(refillId: string, locationId: string, options?: AxiosRequestConfig) {
        return PatientApiFp(this.configuration).refillsRemove(refillId, locationId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PharmacistApi - axios parameter creator
 * @export
 */
export const PharmacistApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} locationId 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] Search for patient_first_name, patient_last_name, rx_number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillsFindByLocation: async (locationId: string, limit?: number, offset?: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('refillsFindByLocation', 'locationId', locationId)
            const localVarPath = `/locations/{location_id}/refills/items`
                .replace(`{${"location_id"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} locationId 
         * @param {UpdateRefillItemDto} updateRefillItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillsUpdateRefillStatus: async (locationId: string, updateRefillItemDto: UpdateRefillItemDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationId' is not null or undefined
            assertParamExists('refillsUpdateRefillStatus', 'locationId', locationId)
            // verify required parameter 'updateRefillItemDto' is not null or undefined
            assertParamExists('refillsUpdateRefillStatus', 'updateRefillItemDto', updateRefillItemDto)
            const localVarPath = `/locations/{location_id}/refills/items`
                .replace(`{${"location_id"}}`, encodeURIComponent(String(locationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRefillItemDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PharmacistApi - functional programming interface
 * @export
 */
export const PharmacistApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PharmacistApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} locationId 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] Search for patient_first_name, patient_last_name, rx_number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refillsFindByLocation(locationId: string, limit?: number, offset?: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RefillSubmittedDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refillsFindByLocation(locationId, limit, offset, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} locationId 
         * @param {UpdateRefillItemDto} updateRefillItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async refillsUpdateRefillStatus(locationId: string, updateRefillItemDto: UpdateRefillItemDto, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RefillItemDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.refillsUpdateRefillStatus(locationId, updateRefillItemDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PharmacistApi - factory interface
 * @export
 */
export const PharmacistApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PharmacistApiFp(configuration)
    return {
        /**
         * 
         * @param {string} locationId 
         * @param {number} [limit] 
         * @param {number} [offset] 
         * @param {string} [search] Search for patient_first_name, patient_last_name, rx_number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillsFindByLocation(locationId: string, limit?: number, offset?: number, search?: string, options?: any): AxiosPromise<Array<RefillSubmittedDto>> {
            return localVarFp.refillsFindByLocation(locationId, limit, offset, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} locationId 
         * @param {UpdateRefillItemDto} updateRefillItemDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillsUpdateRefillStatus(locationId: string, updateRefillItemDto: UpdateRefillItemDto, options?: any): AxiosPromise<RefillItemDto> {
            return localVarFp.refillsUpdateRefillStatus(locationId, updateRefillItemDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PharmacistApi - interface
 * @export
 * @interface PharmacistApi
 */
export interface PharmacistApiInterface {
    /**
     * 
     * @param {string} locationId 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {string} [search] Search for patient_first_name, patient_last_name, rx_number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PharmacistApiInterface
     */
    refillsFindByLocation(locationId: string, limit?: number, offset?: number, search?: string, options?: AxiosRequestConfig): AxiosPromise<Array<RefillSubmittedDto>>;

    /**
     * 
     * @param {string} locationId 
     * @param {UpdateRefillItemDto} updateRefillItemDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PharmacistApiInterface
     */
    refillsUpdateRefillStatus(locationId: string, updateRefillItemDto: UpdateRefillItemDto, options?: AxiosRequestConfig): AxiosPromise<RefillItemDto>;

}

/**
 * PharmacistApi - object-oriented interface
 * @export
 * @class PharmacistApi
 * @extends {BaseAPI}
 */
export class PharmacistApi extends BaseAPI implements PharmacistApiInterface {
    /**
     * 
     * @param {string} locationId 
     * @param {number} [limit] 
     * @param {number} [offset] 
     * @param {string} [search] Search for patient_first_name, patient_last_name, rx_number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PharmacistApi
     */
    public refillsFindByLocation(locationId: string, limit?: number, offset?: number, search?: string, options?: AxiosRequestConfig) {
        return PharmacistApiFp(this.configuration).refillsFindByLocation(locationId, limit, offset, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} locationId 
     * @param {UpdateRefillItemDto} updateRefillItemDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PharmacistApi
     */
    public refillsUpdateRefillStatus(locationId: string, updateRefillItemDto: UpdateRefillItemDto, options?: AxiosRequestConfig) {
        return PharmacistApiFp(this.configuration).refillsUpdateRefillStatus(locationId, updateRefillItemDto, options).then((request) => request(this.axios, this.basePath));
    }
}


