"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Prescription API
 * API for managing patient prescriptions and refills. <br/><br/>        Depends on: <br/>       - <b>PatientService</b> <a href=\"https://api.dev.lumistry.com/patient-service/\">(https://api.dev.lumistry.com/patient-service/)</a><br/>- <b>PmsIntegrationService</b> <a href=\"https://api.dev.lumistry.com/pms-integration-service/\">(https://api.dev.lumistry.com/pms-integration-service/)</a><br/>- <b>OrderService</b> <a href=\"https://api.dev.lumistry.com/order-service/\">(https://api.dev.lumistry.com/order-service/)</a>
 *
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PharmacistApi = exports.PharmacistApiFactory = exports.PharmacistApiFp = exports.PharmacistApiAxiosParamCreator = exports.PatientApi = exports.PatientApiFactory = exports.PatientApiFp = exports.PatientApiAxiosParamCreator = exports.IntegrationApi = exports.IntegrationApiFactory = exports.IntegrationApiFp = exports.IntegrationApiAxiosParamCreator = exports.DefaultApi = exports.DefaultApiFactory = exports.DefaultApiFp = exports.DefaultApiAxiosParamCreator = exports.WorkflowStatus = exports.RxOrigin = exports.RefillStatus = exports.IntegrationType = exports.IngressMethod = exports.HealthStatusStatusEnum = exports.FulfillmentMethod = exports.DoctorContactMethod = void 0;
var axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
var common_1 = require("./common");
// @ts-ignore
var base_1 = require("./base");
/**
 *
 * @export
 * @enum {string}
 */
exports.DoctorContactMethod = {
    Email: 'email',
    Phone: 'phone',
    Fax: 'fax'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.FulfillmentMethod = {
    Pickup: 'pickup',
    Mail: 'mail',
    Delivery: 'delivery',
    Shipping: 'shipping',
    Courier: 'courier',
    DriveThru: 'drive_thru',
    Curbside: 'curbside',
    Counter: 'counter',
    Other: 'other'
};
exports.HealthStatusStatusEnum = {
    Up: 'UP',
    Down: 'DOWN'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.IngressMethod = {
    Web: 'web',
    Ivr: 'ivr',
    Mobile: 'mobile'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.IntegrationType = {
    Manual: 'Manual',
    Integrated: 'Integrated',
    DataFeed: 'DataFeed'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.RefillStatus = {
    Success: 'success',
    NoRefills: 'no_refills',
    Expired: 'expired',
    TooSoon: 'too_soon',
    NotFound: 'not_found',
    Controlled: 'controlled',
    Narcotic: 'narcotic',
    Transferred: 'transferred',
    Discontinued: 'discontinued',
    OnHold: 'on_hold',
    Deleted: 'deleted',
    Fillable: 'fillable',
    PartiallyFilled: 'partially_filled',
    OutOfStock: 'out_of_stock',
    SpecialOrder: 'special_order',
    Deceased: 'deceased',
    RejectedReasonUnknown: 'rejected_reason_unknown'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.RxOrigin = {
    Written: 'written',
    Phoned: 'phoned',
    Escribed: 'escribed'
};
/**
 *
 * @export
 * @enum {string}
 */
exports.WorkflowStatus = {
    InProcess: 'in_process',
    ReadyForPickup: 'ready_for_pickup',
    ReadyForShipping: 'ready_for_shipping',
    ReadyForDelivery: 'ready_for_delivery',
    Mail: 'mail',
    Shipped: 'shipped',
    DeliveryInProgress: 'delivery_in_progress',
    Dispensed: 'dispensed',
    Returned: 'returned',
    Delivered: 'delivered',
    Cancelled: 'cancelled',
    Transferred: 'transferred',
    Expired: 'expired',
    Discontinued: 'discontinued',
    InQueue: 'in_queue',
    PartialFill: 'partial_fill',
    PartiallyFilled: 'partially_filled',
    OutOfStock: 'out_of_stock',
    SpecialOrder: 'special_order',
    AwaitingInsuranceAuthorization: 'awaiting_insurance_authorization',
    AwaitingPrescriberAuthorization: 'awaiting_prescriber_authorization',
    InsuranceRefused: 'insurance_refused',
    PrescriberRefused: 'prescriber_refused',
    ReturnedToStock: 'returned_to_stock'
};
/**
 * DefaultApi - axios parameter creator
 * @export
 */
var DefaultApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Get health status of current service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheckGet: function (options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    localVarPath = "/health-check";
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
exports.DefaultApiAxiosParamCreator = DefaultApiAxiosParamCreator;
/**
 * DefaultApi - functional programming interface
 * @export
 */
var DefaultApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.DefaultApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Get health status of current service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheckGet: function (options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.healthCheckGet(options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.DefaultApiFp = DefaultApiFp;
/**
 * DefaultApi - factory interface
 * @export
 */
var DefaultApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.DefaultApiFp)(configuration);
    return {
        /**
         *
         * @summary Get health status of current service.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheckGet: function (options) {
            return localVarFp.healthCheckGet(options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.DefaultApiFactory = DefaultApiFactory;
/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
var DefaultApi = /** @class */ (function (_super) {
    __extends(DefaultApi, _super);
    function DefaultApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Get health status of current service.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    DefaultApi.prototype.healthCheckGet = function (options) {
        var _this = this;
        return (0, exports.DefaultApiFp)(this.configuration).healthCheckGet(options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return DefaultApi;
}(base_1.BaseAPI));
exports.DefaultApi = DefaultApi;
/**
 * IntegrationApi - axios parameter creator
 * @export
 */
var IntegrationApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @summary Create or Update location config
         * @param {string} locationId
         * @param {UpdateLocationConfigDto} updateLocationConfigDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationConfigCreate: function (locationId, updateLocationConfigDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('locationConfigCreate', 'locationId', locationId);
                    // verify required parameter 'updateLocationConfigDto' is not null or undefined
                    (0, common_1.assertParamExists)('locationConfigCreate', 'updateLocationConfigDto', updateLocationConfigDto);
                    localVarPath = "/locations/{location_id}/config"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PUT' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updateLocationConfigDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Get location config by location_id
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationConfigFind: function (locationId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('locationConfigFind', 'locationId', locationId);
                    localVarPath = "/locations/{location_id}/config"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Delete forever a location config by location_id
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationConfigRemove: function (locationId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('locationConfigRemove', 'locationId', locationId);
                    localVarPath = "/locations/{location_id}/config"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
exports.IntegrationApiAxiosParamCreator = IntegrationApiAxiosParamCreator;
/**
 * IntegrationApi - functional programming interface
 * @export
 */
var IntegrationApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.IntegrationApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @summary Create or Update location config
         * @param {string} locationId
         * @param {UpdateLocationConfigDto} updateLocationConfigDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationConfigCreate: function (locationId, updateLocationConfigDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.locationConfigCreate(locationId, updateLocationConfigDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Get location config by location_id
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationConfigFind: function (locationId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.locationConfigFind(locationId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Delete forever a location config by location_id
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationConfigRemove: function (locationId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.locationConfigRemove(locationId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.IntegrationApiFp = IntegrationApiFp;
/**
 * IntegrationApi - factory interface
 * @export
 */
var IntegrationApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.IntegrationApiFp)(configuration);
    return {
        /**
         *
         * @summary Create or Update location config
         * @param {string} locationId
         * @param {UpdateLocationConfigDto} updateLocationConfigDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationConfigCreate: function (locationId, updateLocationConfigDto, options) {
            return localVarFp.locationConfigCreate(locationId, updateLocationConfigDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Get location config by location_id
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationConfigFind: function (locationId, options) {
            return localVarFp.locationConfigFind(locationId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Delete forever a location config by location_id
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        locationConfigRemove: function (locationId, options) {
            return localVarFp.locationConfigRemove(locationId, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.IntegrationApiFactory = IntegrationApiFactory;
/**
 * IntegrationApi - object-oriented interface
 * @export
 * @class IntegrationApi
 * @extends {BaseAPI}
 */
var IntegrationApi = /** @class */ (function (_super) {
    __extends(IntegrationApi, _super);
    function IntegrationApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @summary Create or Update location config
     * @param {string} locationId
     * @param {UpdateLocationConfigDto} updateLocationConfigDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    IntegrationApi.prototype.locationConfigCreate = function (locationId, updateLocationConfigDto, options) {
        var _this = this;
        return (0, exports.IntegrationApiFp)(this.configuration).locationConfigCreate(locationId, updateLocationConfigDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Get location config by location_id
     * @param {string} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    IntegrationApi.prototype.locationConfigFind = function (locationId, options) {
        var _this = this;
        return (0, exports.IntegrationApiFp)(this.configuration).locationConfigFind(locationId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Delete forever a location config by location_id
     * @param {string} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    IntegrationApi.prototype.locationConfigRemove = function (locationId, options) {
        var _this = this;
        return (0, exports.IntegrationApiFp)(this.configuration).locationConfigRemove(locationId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return IntegrationApi;
}(base_1.BaseAPI));
exports.IntegrationApi = IntegrationApi;
/**
 * PatientApi - axios parameter creator
 * @export
 */
var PatientApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {string} patientId Patient Record Identifier
         * @param {string} locationId
         * @param {CreatePrescriptionDto} createPrescriptionDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prescriptionCreateOrUpdate: function (patientId, locationId, createPrescriptionDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'patientId' is not null or undefined
                    (0, common_1.assertParamExists)('prescriptionCreateOrUpdate', 'patientId', patientId);
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('prescriptionCreateOrUpdate', 'locationId', locationId);
                    // verify required parameter 'createPrescriptionDto' is not null or undefined
                    (0, common_1.assertParamExists)('prescriptionCreateOrUpdate', 'createPrescriptionDto', createPrescriptionDto);
                    localVarPath = "/locations/{location_id}/patients/{patient_id}/prescriptions"
                        .replace("{".concat("patient_id", "}"), encodeURIComponent(String(patientId)))
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(createPrescriptionDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} patientId Patient Record Identifier
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prescriptionFindAll: function (patientId, locationId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'patientId' is not null or undefined
                    (0, common_1.assertParamExists)('prescriptionFindAll', 'patientId', patientId);
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('prescriptionFindAll', 'locationId', locationId);
                    localVarPath = "/locations/{location_id}/patients/{patient_id}/prescriptions"
                        .replace("{".concat("patient_id", "}"), encodeURIComponent(String(patientId)))
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} idOrRx
         * @param {string} patientId Patient Record Identifier
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prescriptionFindOne: function (idOrRx, patientId, locationId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'idOrRx' is not null or undefined
                    (0, common_1.assertParamExists)('prescriptionFindOne', 'idOrRx', idOrRx);
                    // verify required parameter 'patientId' is not null or undefined
                    (0, common_1.assertParamExists)('prescriptionFindOne', 'patientId', patientId);
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('prescriptionFindOne', 'locationId', locationId);
                    localVarPath = "/locations/{location_id}/patients/{patient_id}/prescriptions/{id_or_rx}"
                        .replace("{".concat("id_or_rx", "}"), encodeURIComponent(String(idOrRx)))
                        .replace("{".concat("patient_id", "}"), encodeURIComponent(String(patientId)))
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         * Get Prescription information from PMS / DataFeed depending how to location is configured.       When location is PMS integrated then also ensure to create or update Patient and Prescription data to our system.       When location is DataFeed then query only the database for data. The patient/prescription data should be already on our system
         * @summary Get prescription by patient identifiers
         * @param {string} rxNumber
         * @param {string} lastName
         * @param {string} dateOfBirth
         * @param {string} locationId
         * @param {string} [xPharmacyId] This header should be present when location is PMS integrated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prescriptionGetByRx: function (rxNumber, lastName, dateOfBirth, locationId, xPharmacyId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'rxNumber' is not null or undefined
                    (0, common_1.assertParamExists)('prescriptionGetByRx', 'rxNumber', rxNumber);
                    // verify required parameter 'lastName' is not null or undefined
                    (0, common_1.assertParamExists)('prescriptionGetByRx', 'lastName', lastName);
                    // verify required parameter 'dateOfBirth' is not null or undefined
                    (0, common_1.assertParamExists)('prescriptionGetByRx', 'dateOfBirth', dateOfBirth);
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('prescriptionGetByRx', 'locationId', locationId);
                    localVarPath = "/locations/{location_id}/prescriptions"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (rxNumber !== undefined) {
                        localVarQueryParameter['rx_number'] = rxNumber;
                    }
                    if (lastName !== undefined) {
                        localVarQueryParameter['last_name'] = lastName;
                    }
                    if (dateOfBirth !== undefined) {
                        localVarQueryParameter['date_of_birth'] = dateOfBirth;
                    }
                    if (xPharmacyId != null) {
                        localVarHeaderParameter['x-pharmacy-id'] = String(xPharmacyId);
                    }
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} prescriptionId
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prescriptionHistoryFindAll: function (prescriptionId, locationId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'prescriptionId' is not null or undefined
                    (0, common_1.assertParamExists)('prescriptionHistoryFindAll', 'prescriptionId', prescriptionId);
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('prescriptionHistoryFindAll', 'locationId', locationId);
                    localVarPath = "/locations/{location_id}/prescriptions/{prescription_id}/history"
                        .replace("{".concat("prescription_id", "}"), encodeURIComponent(String(prescriptionId)))
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} patientId Patient Record Identifier
         * @param {string} locationId
         * @param {MigratePrescriptionDto} migratePrescriptionDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prescriptionMigrate: function (patientId, locationId, migratePrescriptionDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'patientId' is not null or undefined
                    (0, common_1.assertParamExists)('prescriptionMigrate', 'patientId', patientId);
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('prescriptionMigrate', 'locationId', locationId);
                    // verify required parameter 'migratePrescriptionDto' is not null or undefined
                    (0, common_1.assertParamExists)('prescriptionMigrate', 'migratePrescriptionDto', migratePrescriptionDto);
                    localVarPath = "/locations/{location_id}/patients/{patient_id}/prescriptions/migrate"
                        .replace("{".concat("patient_id", "}"), encodeURIComponent(String(patientId)))
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(migratePrescriptionDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} prescriptionId
         * @param {string} patientId Patient Record Identifier
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prescriptionRemove: function (prescriptionId, patientId, locationId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'prescriptionId' is not null or undefined
                    (0, common_1.assertParamExists)('prescriptionRemove', 'prescriptionId', prescriptionId);
                    // verify required parameter 'patientId' is not null or undefined
                    (0, common_1.assertParamExists)('prescriptionRemove', 'patientId', patientId);
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('prescriptionRemove', 'locationId', locationId);
                    localVarPath = "/locations/{location_id}/patients/{patient_id}/prescriptions/{prescription_id}"
                        .replace("{".concat("prescription_id", "}"), encodeURIComponent(String(prescriptionId)))
                        .replace("{".concat("patient_id", "}"), encodeURIComponent(String(patientId)))
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} prescriptionId
         * @param {string} patientId Patient Record Identifier
         * @param {string} locationId
         * @param {UpdatePrescriptionDto} updatePrescriptionDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prescriptionUpdate: function (prescriptionId, patientId, locationId, updatePrescriptionDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'prescriptionId' is not null or undefined
                    (0, common_1.assertParamExists)('prescriptionUpdate', 'prescriptionId', prescriptionId);
                    // verify required parameter 'patientId' is not null or undefined
                    (0, common_1.assertParamExists)('prescriptionUpdate', 'patientId', patientId);
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('prescriptionUpdate', 'locationId', locationId);
                    // verify required parameter 'updatePrescriptionDto' is not null or undefined
                    (0, common_1.assertParamExists)('prescriptionUpdate', 'updatePrescriptionDto', updatePrescriptionDto);
                    localVarPath = "/locations/{location_id}/patients/{patient_id}/prescriptions/{prescription_id}"
                        .replace("{".concat("prescription_id", "}"), encodeURIComponent(String(prescriptionId)))
                        .replace("{".concat("patient_id", "}"), encodeURIComponent(String(patientId)))
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updatePrescriptionDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Submit a refill for multiple prescription
         * @param {string} locationId
         * @param {CreateRefillDto} createRefillDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillsCreate: function (locationId, createRefillDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('refillsCreate', 'locationId', locationId);
                    // verify required parameter 'createRefillDto' is not null or undefined
                    (0, common_1.assertParamExists)('refillsCreate', 'createRefillDto', createRefillDto);
                    localVarPath = "/locations/{location_id}/refills"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(createRefillDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {Array<string>} [patientId] Array of Patient ids
         * @param {string} [submittedBy]
         * @param {string} [orderId]
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {string} [sort]
         * @param {boolean} [includeRefillItems]
         * @param {boolean} [includePrescriptions]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillsFind: function (locationId, patientId, submittedBy, orderId, limit, offset, sort, includeRefillItems, includePrescriptions, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('refillsFind', 'locationId', locationId);
                    localVarPath = "/locations/{location_id}/refills"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (patientId) {
                        localVarQueryParameter['patient_id'] = patientId;
                    }
                    if (submittedBy !== undefined) {
                        localVarQueryParameter['submitted_by'] = submittedBy;
                    }
                    if (orderId !== undefined) {
                        localVarQueryParameter['order_id'] = orderId;
                    }
                    if (limit !== undefined) {
                        localVarQueryParameter['limit'] = limit;
                    }
                    if (offset !== undefined) {
                        localVarQueryParameter['offset'] = offset;
                    }
                    if (sort !== undefined) {
                        localVarQueryParameter['sort'] = sort;
                    }
                    if (includeRefillItems !== undefined) {
                        localVarQueryParameter['include_refill_items'] = includeRefillItems;
                    }
                    if (includePrescriptions !== undefined) {
                        localVarQueryParameter['include_prescriptions'] = includePrescriptions;
                    }
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} refillId
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillsFindOne: function (refillId, locationId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'refillId' is not null or undefined
                    (0, common_1.assertParamExists)('refillsFindOne', 'refillId', refillId);
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('refillsFindOne', 'locationId', locationId);
                    localVarPath = "/locations/{location_id}/refills/{refill_id}"
                        .replace("{".concat("refill_id", "}"), encodeURIComponent(String(refillId)))
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @summary Submit a refill for multiple prescription
         * @param {string} locationId
         * @param {MigrateRefillDto} migrateRefillDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillsMigrate: function (locationId, migrateRefillDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('refillsMigrate', 'locationId', locationId);
                    // verify required parameter 'migrateRefillDto' is not null or undefined
                    (0, common_1.assertParamExists)('refillsMigrate', 'migrateRefillDto', migrateRefillDto);
                    localVarPath = "/locations/{location_id}/refills/migrate"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'POST' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(migrateRefillDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} refillId
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillsRemove: function (refillId, locationId, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'refillId' is not null or undefined
                    (0, common_1.assertParamExists)('refillsRemove', 'refillId', refillId);
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('refillsRemove', 'locationId', locationId);
                    localVarPath = "/locations/{location_id}/refills/{refill_id}"
                        .replace("{".concat("refill_id", "}"), encodeURIComponent(String(refillId)))
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'DELETE' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
exports.PatientApiAxiosParamCreator = PatientApiAxiosParamCreator;
/**
 * PatientApi - functional programming interface
 * @export
 */
var PatientApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.PatientApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {string} patientId Patient Record Identifier
         * @param {string} locationId
         * @param {CreatePrescriptionDto} createPrescriptionDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prescriptionCreateOrUpdate: function (patientId, locationId, createPrescriptionDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.prescriptionCreateOrUpdate(patientId, locationId, createPrescriptionDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} patientId Patient Record Identifier
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prescriptionFindAll: function (patientId, locationId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.prescriptionFindAll(patientId, locationId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} idOrRx
         * @param {string} patientId Patient Record Identifier
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prescriptionFindOne: function (idOrRx, patientId, locationId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.prescriptionFindOne(idOrRx, patientId, locationId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         * Get Prescription information from PMS / DataFeed depending how to location is configured.       When location is PMS integrated then also ensure to create or update Patient and Prescription data to our system.       When location is DataFeed then query only the database for data. The patient/prescription data should be already on our system
         * @summary Get prescription by patient identifiers
         * @param {string} rxNumber
         * @param {string} lastName
         * @param {string} dateOfBirth
         * @param {string} locationId
         * @param {string} [xPharmacyId] This header should be present when location is PMS integrated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prescriptionGetByRx: function (rxNumber, lastName, dateOfBirth, locationId, xPharmacyId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.prescriptionGetByRx(rxNumber, lastName, dateOfBirth, locationId, xPharmacyId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} prescriptionId
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prescriptionHistoryFindAll: function (prescriptionId, locationId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.prescriptionHistoryFindAll(prescriptionId, locationId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} patientId Patient Record Identifier
         * @param {string} locationId
         * @param {MigratePrescriptionDto} migratePrescriptionDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prescriptionMigrate: function (patientId, locationId, migratePrescriptionDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.prescriptionMigrate(patientId, locationId, migratePrescriptionDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} prescriptionId
         * @param {string} patientId Patient Record Identifier
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prescriptionRemove: function (prescriptionId, patientId, locationId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.prescriptionRemove(prescriptionId, patientId, locationId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} prescriptionId
         * @param {string} patientId Patient Record Identifier
         * @param {string} locationId
         * @param {UpdatePrescriptionDto} updatePrescriptionDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prescriptionUpdate: function (prescriptionId, patientId, locationId, updatePrescriptionDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.prescriptionUpdate(prescriptionId, patientId, locationId, updatePrescriptionDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Submit a refill for multiple prescription
         * @param {string} locationId
         * @param {CreateRefillDto} createRefillDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillsCreate: function (locationId, createRefillDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.refillsCreate(locationId, createRefillDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {Array<string>} [patientId] Array of Patient ids
         * @param {string} [submittedBy]
         * @param {string} [orderId]
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {string} [sort]
         * @param {boolean} [includeRefillItems]
         * @param {boolean} [includePrescriptions]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillsFind: function (locationId, patientId, submittedBy, orderId, limit, offset, sort, includeRefillItems, includePrescriptions, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.refillsFind(locationId, patientId, submittedBy, orderId, limit, offset, sort, includeRefillItems, includePrescriptions, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} refillId
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillsFindOne: function (refillId, locationId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.refillsFindOne(refillId, locationId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @summary Submit a refill for multiple prescription
         * @param {string} locationId
         * @param {MigrateRefillDto} migrateRefillDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillsMigrate: function (locationId, migrateRefillDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.refillsMigrate(locationId, migrateRefillDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} refillId
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillsRemove: function (refillId, locationId, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.refillsRemove(refillId, locationId, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.PatientApiFp = PatientApiFp;
/**
 * PatientApi - factory interface
 * @export
 */
var PatientApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.PatientApiFp)(configuration);
    return {
        /**
         *
         * @param {string} patientId Patient Record Identifier
         * @param {string} locationId
         * @param {CreatePrescriptionDto} createPrescriptionDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prescriptionCreateOrUpdate: function (patientId, locationId, createPrescriptionDto, options) {
            return localVarFp.prescriptionCreateOrUpdate(patientId, locationId, createPrescriptionDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} patientId Patient Record Identifier
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prescriptionFindAll: function (patientId, locationId, options) {
            return localVarFp.prescriptionFindAll(patientId, locationId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} idOrRx
         * @param {string} patientId Patient Record Identifier
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prescriptionFindOne: function (idOrRx, patientId, locationId, options) {
            return localVarFp.prescriptionFindOne(idOrRx, patientId, locationId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         * Get Prescription information from PMS / DataFeed depending how to location is configured.       When location is PMS integrated then also ensure to create or update Patient and Prescription data to our system.       When location is DataFeed then query only the database for data. The patient/prescription data should be already on our system
         * @summary Get prescription by patient identifiers
         * @param {string} rxNumber
         * @param {string} lastName
         * @param {string} dateOfBirth
         * @param {string} locationId
         * @param {string} [xPharmacyId] This header should be present when location is PMS integrated.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prescriptionGetByRx: function (rxNumber, lastName, dateOfBirth, locationId, xPharmacyId, options) {
            return localVarFp.prescriptionGetByRx(rxNumber, lastName, dateOfBirth, locationId, xPharmacyId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} prescriptionId
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prescriptionHistoryFindAll: function (prescriptionId, locationId, options) {
            return localVarFp.prescriptionHistoryFindAll(prescriptionId, locationId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} patientId Patient Record Identifier
         * @param {string} locationId
         * @param {MigratePrescriptionDto} migratePrescriptionDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prescriptionMigrate: function (patientId, locationId, migratePrescriptionDto, options) {
            return localVarFp.prescriptionMigrate(patientId, locationId, migratePrescriptionDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} prescriptionId
         * @param {string} patientId Patient Record Identifier
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prescriptionRemove: function (prescriptionId, patientId, locationId, options) {
            return localVarFp.prescriptionRemove(prescriptionId, patientId, locationId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} prescriptionId
         * @param {string} patientId Patient Record Identifier
         * @param {string} locationId
         * @param {UpdatePrescriptionDto} updatePrescriptionDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        prescriptionUpdate: function (prescriptionId, patientId, locationId, updatePrescriptionDto, options) {
            return localVarFp.prescriptionUpdate(prescriptionId, patientId, locationId, updatePrescriptionDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Submit a refill for multiple prescription
         * @param {string} locationId
         * @param {CreateRefillDto} createRefillDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillsCreate: function (locationId, createRefillDto, options) {
            return localVarFp.refillsCreate(locationId, createRefillDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {Array<string>} [patientId] Array of Patient ids
         * @param {string} [submittedBy]
         * @param {string} [orderId]
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {string} [sort]
         * @param {boolean} [includeRefillItems]
         * @param {boolean} [includePrescriptions]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillsFind: function (locationId, patientId, submittedBy, orderId, limit, offset, sort, includeRefillItems, includePrescriptions, options) {
            return localVarFp.refillsFind(locationId, patientId, submittedBy, orderId, limit, offset, sort, includeRefillItems, includePrescriptions, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} refillId
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillsFindOne: function (refillId, locationId, options) {
            return localVarFp.refillsFindOne(refillId, locationId, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @summary Submit a refill for multiple prescription
         * @param {string} locationId
         * @param {MigrateRefillDto} migrateRefillDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillsMigrate: function (locationId, migrateRefillDto, options) {
            return localVarFp.refillsMigrate(locationId, migrateRefillDto, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} refillId
         * @param {string} locationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillsRemove: function (refillId, locationId, options) {
            return localVarFp.refillsRemove(refillId, locationId, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.PatientApiFactory = PatientApiFactory;
/**
 * PatientApi - object-oriented interface
 * @export
 * @class PatientApi
 * @extends {BaseAPI}
 */
var PatientApi = /** @class */ (function (_super) {
    __extends(PatientApi, _super);
    function PatientApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {string} patientId Patient Record Identifier
     * @param {string} locationId
     * @param {CreatePrescriptionDto} createPrescriptionDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    PatientApi.prototype.prescriptionCreateOrUpdate = function (patientId, locationId, createPrescriptionDto, options) {
        var _this = this;
        return (0, exports.PatientApiFp)(this.configuration).prescriptionCreateOrUpdate(patientId, locationId, createPrescriptionDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} patientId Patient Record Identifier
     * @param {string} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    PatientApi.prototype.prescriptionFindAll = function (patientId, locationId, options) {
        var _this = this;
        return (0, exports.PatientApiFp)(this.configuration).prescriptionFindAll(patientId, locationId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} idOrRx
     * @param {string} patientId Patient Record Identifier
     * @param {string} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    PatientApi.prototype.prescriptionFindOne = function (idOrRx, patientId, locationId, options) {
        var _this = this;
        return (0, exports.PatientApiFp)(this.configuration).prescriptionFindOne(idOrRx, patientId, locationId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     * Get Prescription information from PMS / DataFeed depending how to location is configured.       When location is PMS integrated then also ensure to create or update Patient and Prescription data to our system.       When location is DataFeed then query only the database for data. The patient/prescription data should be already on our system
     * @summary Get prescription by patient identifiers
     * @param {string} rxNumber
     * @param {string} lastName
     * @param {string} dateOfBirth
     * @param {string} locationId
     * @param {string} [xPharmacyId] This header should be present when location is PMS integrated.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    PatientApi.prototype.prescriptionGetByRx = function (rxNumber, lastName, dateOfBirth, locationId, xPharmacyId, options) {
        var _this = this;
        return (0, exports.PatientApiFp)(this.configuration).prescriptionGetByRx(rxNumber, lastName, dateOfBirth, locationId, xPharmacyId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} prescriptionId
     * @param {string} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    PatientApi.prototype.prescriptionHistoryFindAll = function (prescriptionId, locationId, options) {
        var _this = this;
        return (0, exports.PatientApiFp)(this.configuration).prescriptionHistoryFindAll(prescriptionId, locationId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} patientId Patient Record Identifier
     * @param {string} locationId
     * @param {MigratePrescriptionDto} migratePrescriptionDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    PatientApi.prototype.prescriptionMigrate = function (patientId, locationId, migratePrescriptionDto, options) {
        var _this = this;
        return (0, exports.PatientApiFp)(this.configuration).prescriptionMigrate(patientId, locationId, migratePrescriptionDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} prescriptionId
     * @param {string} patientId Patient Record Identifier
     * @param {string} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    PatientApi.prototype.prescriptionRemove = function (prescriptionId, patientId, locationId, options) {
        var _this = this;
        return (0, exports.PatientApiFp)(this.configuration).prescriptionRemove(prescriptionId, patientId, locationId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} prescriptionId
     * @param {string} patientId Patient Record Identifier
     * @param {string} locationId
     * @param {UpdatePrescriptionDto} updatePrescriptionDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    PatientApi.prototype.prescriptionUpdate = function (prescriptionId, patientId, locationId, updatePrescriptionDto, options) {
        var _this = this;
        return (0, exports.PatientApiFp)(this.configuration).prescriptionUpdate(prescriptionId, patientId, locationId, updatePrescriptionDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Submit a refill for multiple prescription
     * @param {string} locationId
     * @param {CreateRefillDto} createRefillDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    PatientApi.prototype.refillsCreate = function (locationId, createRefillDto, options) {
        var _this = this;
        return (0, exports.PatientApiFp)(this.configuration).refillsCreate(locationId, createRefillDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {Array<string>} [patientId] Array of Patient ids
     * @param {string} [submittedBy]
     * @param {string} [orderId]
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [sort]
     * @param {boolean} [includeRefillItems]
     * @param {boolean} [includePrescriptions]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    PatientApi.prototype.refillsFind = function (locationId, patientId, submittedBy, orderId, limit, offset, sort, includeRefillItems, includePrescriptions, options) {
        var _this = this;
        return (0, exports.PatientApiFp)(this.configuration).refillsFind(locationId, patientId, submittedBy, orderId, limit, offset, sort, includeRefillItems, includePrescriptions, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} refillId
     * @param {string} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    PatientApi.prototype.refillsFindOne = function (refillId, locationId, options) {
        var _this = this;
        return (0, exports.PatientApiFp)(this.configuration).refillsFindOne(refillId, locationId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @summary Submit a refill for multiple prescription
     * @param {string} locationId
     * @param {MigrateRefillDto} migrateRefillDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    PatientApi.prototype.refillsMigrate = function (locationId, migrateRefillDto, options) {
        var _this = this;
        return (0, exports.PatientApiFp)(this.configuration).refillsMigrate(locationId, migrateRefillDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} refillId
     * @param {string} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PatientApi
     */
    PatientApi.prototype.refillsRemove = function (refillId, locationId, options) {
        var _this = this;
        return (0, exports.PatientApiFp)(this.configuration).refillsRemove(refillId, locationId, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return PatientApi;
}(base_1.BaseAPI));
exports.PatientApi = PatientApi;
/**
 * PharmacistApi - axios parameter creator
 * @export
 */
var PharmacistApiAxiosParamCreator = function (configuration) {
    var _this = this;
    return {
        /**
         *
         * @param {string} locationId
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {string} [search] Search for patient_first_name, patient_last_name, rx_number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillsFindByLocation: function (locationId, limit, offset, search, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('refillsFindByLocation', 'locationId', locationId);
                    localVarPath = "/locations/{location_id}/refills/items"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'GET' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    if (limit !== undefined) {
                        localVarQueryParameter['limit'] = limit;
                    }
                    if (offset !== undefined) {
                        localVarQueryParameter['offset'] = offset;
                    }
                    if (search !== undefined) {
                        localVarQueryParameter['search'] = search;
                    }
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {UpdateRefillItemDto} updateRefillItemDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillsUpdateRefillStatus: function (locationId, updateRefillItemDto, options) {
            if (options === void 0) { options = {}; }
            return __awaiter(_this, void 0, void 0, function () {
                var localVarPath, localVarUrlObj, baseOptions, localVarRequestOptions, localVarHeaderParameter, localVarQueryParameter, headersFromBaseOptions;
                return __generator(this, function (_a) {
                    // verify required parameter 'locationId' is not null or undefined
                    (0, common_1.assertParamExists)('refillsUpdateRefillStatus', 'locationId', locationId);
                    // verify required parameter 'updateRefillItemDto' is not null or undefined
                    (0, common_1.assertParamExists)('refillsUpdateRefillStatus', 'updateRefillItemDto', updateRefillItemDto);
                    localVarPath = "/locations/{location_id}/refills/items"
                        .replace("{".concat("location_id", "}"), encodeURIComponent(String(locationId)));
                    localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
                    if (configuration) {
                        baseOptions = configuration.baseOptions;
                    }
                    localVarRequestOptions = __assign(__assign({ method: 'PATCH' }, baseOptions), options);
                    localVarHeaderParameter = {};
                    localVarQueryParameter = {};
                    localVarHeaderParameter['Content-Type'] = 'application/json';
                    (0, common_1.setSearchParams)(localVarUrlObj, localVarQueryParameter);
                    headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
                    localVarRequestOptions.headers = __assign(__assign(__assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
                    localVarRequestOptions.data = (0, common_1.serializeDataIfNeeded)(updateRefillItemDto, localVarRequestOptions, configuration);
                    return [2 /*return*/, {
                            url: (0, common_1.toPathString)(localVarUrlObj),
                            options: localVarRequestOptions,
                        }];
                });
            });
        },
    };
};
exports.PharmacistApiAxiosParamCreator = PharmacistApiAxiosParamCreator;
/**
 * PharmacistApi - functional programming interface
 * @export
 */
var PharmacistApiFp = function (configuration) {
    var localVarAxiosParamCreator = (0, exports.PharmacistApiAxiosParamCreator)(configuration);
    return {
        /**
         *
         * @param {string} locationId
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {string} [search] Search for patient_first_name, patient_last_name, rx_number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillsFindByLocation: function (locationId, limit, offset, search, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.refillsFindByLocation(locationId, limit, offset, search, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
        /**
         *
         * @param {string} locationId
         * @param {UpdateRefillItemDto} updateRefillItemDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillsUpdateRefillStatus: function (locationId, updateRefillItemDto, options) {
            return __awaiter(this, void 0, void 0, function () {
                var localVarAxiosArgs;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, localVarAxiosParamCreator.refillsUpdateRefillStatus(locationId, updateRefillItemDto, options)];
                        case 1:
                            localVarAxiosArgs = _a.sent();
                            return [2 /*return*/, (0, common_1.createRequestFunction)(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration)];
                    }
                });
            });
        },
    };
};
exports.PharmacistApiFp = PharmacistApiFp;
/**
 * PharmacistApi - factory interface
 * @export
 */
var PharmacistApiFactory = function (configuration, basePath, axios) {
    var localVarFp = (0, exports.PharmacistApiFp)(configuration);
    return {
        /**
         *
         * @param {string} locationId
         * @param {number} [limit]
         * @param {number} [offset]
         * @param {string} [search] Search for patient_first_name, patient_last_name, rx_number
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillsFindByLocation: function (locationId, limit, offset, search, options) {
            return localVarFp.refillsFindByLocation(locationId, limit, offset, search, options).then(function (request) { return request(axios, basePath); });
        },
        /**
         *
         * @param {string} locationId
         * @param {UpdateRefillItemDto} updateRefillItemDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refillsUpdateRefillStatus: function (locationId, updateRefillItemDto, options) {
            return localVarFp.refillsUpdateRefillStatus(locationId, updateRefillItemDto, options).then(function (request) { return request(axios, basePath); });
        },
    };
};
exports.PharmacistApiFactory = PharmacistApiFactory;
/**
 * PharmacistApi - object-oriented interface
 * @export
 * @class PharmacistApi
 * @extends {BaseAPI}
 */
var PharmacistApi = /** @class */ (function (_super) {
    __extends(PharmacistApi, _super);
    function PharmacistApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     *
     * @param {string} locationId
     * @param {number} [limit]
     * @param {number} [offset]
     * @param {string} [search] Search for patient_first_name, patient_last_name, rx_number
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PharmacistApi
     */
    PharmacistApi.prototype.refillsFindByLocation = function (locationId, limit, offset, search, options) {
        var _this = this;
        return (0, exports.PharmacistApiFp)(this.configuration).refillsFindByLocation(locationId, limit, offset, search, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    /**
     *
     * @param {string} locationId
     * @param {UpdateRefillItemDto} updateRefillItemDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PharmacistApi
     */
    PharmacistApi.prototype.refillsUpdateRefillStatus = function (locationId, updateRefillItemDto, options) {
        var _this = this;
        return (0, exports.PharmacistApiFp)(this.configuration).refillsUpdateRefillStatus(locationId, updateRefillItemDto, options).then(function (request) { return request(_this.axios, _this.basePath); });
    };
    return PharmacistApi;
}(base_1.BaseAPI));
exports.PharmacistApi = PharmacistApi;
